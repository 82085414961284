import React, { useState } from "react";
import { Select, Stack, Input, Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
function QuizTimelimit({ onChange, timelimitValue }) {
  const [checked, setChecked] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const setTimelimit = (time) => {
    onChange(time);
  };
  const MinutesHandler = (event) => {
    const newMinutes = parseInt(event.target.value || 0, 10);
    if (!isNaN(newMinutes) && newMinutes >= 0) {
      setMinutes(newMinutes);
      onChange(newMinutes * 60 + seconds);
    }
  };
  const SecondsHandler = (event) => {
    const newSeconds = parseInt(event.target.value || 0, 10);
    if (!isNaN(newSeconds) && newSeconds >= 0) {
      setSeconds(newSeconds);
      onChange(minutes * 60 + newSeconds);
    }
  };
  const { t } = useTranslation();
  return (
    <Stack direction={"column"} spacing={4} w={"full"}>
      {/* <Heading fontSize={"lg"}>4. 시간</Heading> */}
      <Heading fontSize={"lg"}>
        {t("quizpang.quizbattlemanage.questioncard.text.time")}
      </Heading>

      <Stack direction={"row"}>
        <Select
          width={158}
          value={checked ? "직접 입력" : timelimitValue}
          onChange={(e) => {
            const isEnteringManually = e.target.value === "직접 입력";
            setChecked(isEnteringManually);
            setTimelimit(isEnteringManually ? 0 : e.target.value);
          }}
        >
          {[10, 30, 60, 180, 300, 600, 900, "직접 입력"].map((x) => (
            <option key={x} value={x}>
              {x === "직접 입력"
                ? t(
                    "quizpang.quizbattlemanage.questioncard.timer.dropdown.freeinput"
                  )
                : x >= 60
                ? x / 60 + t("timetable.quizpang.card.text.minute")
                : x + t("timetable.quizpang.card.text.second")}
            </option>
          ))}
        </Select>

        <Stack direction={["column", "row"]} spacing="17px">
          <div style={{ position: "relative" }}>
            <Input
              variant="standard"
              style={{ width: 58 }}
              disabled={!checked}
              value={Math.floor(timelimitValue / 60)}
              onChange={MinutesHandler}
            />
            <span style={{ position: "absolute", right: 0, bottom: 4 }}>
              {/* 분 */}
              {t("timetable.quizpang.card.text.minute")}
            </span>
          </div>
          <div style={{ position: "relative" }}>
            <Input
              variant="standard"
              style={{ width: 58 }}
              disabled={!checked}
              value={Math.floor(timelimitValue % 60)}
              onChange={SecondsHandler}
            />
            <span style={{ position: "absolute", right: 0, bottom: 4 }}>
              {/* 초 */}
              {t("timetable.quizpang.card.text.second")}
            </span>
          </div>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default QuizTimelimit;

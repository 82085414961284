import { Button, HStack, Heading, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import { TextEditorForQuizpang } from "../Input/TextEditorForQuizpang";
import { ImageMediaInput } from "./ImageMediaInput";
import { useTranslation } from "react-i18next";
const QuizTitle = ({
  setLinkType,
  imageFile,
  setImageFile,
  uploadedImgPath,
  setUploadedImgPath,
  videoLink,
  setVideoLink,
  quizTitleValue,
  onChangeQuizTitle,
  setIsVideoModalOpen,
}) => {
  const handleTextChange = (newValue) => {
    onChangeQuizTitle(newValue);
  };
  const openModal = () => {
    setIsVideoModalOpen(true);
  };
  const removeVideoLink = () => {
    setLinkType(null);
    setVideoLink("");
  };
  const removeImage = () => {
    setLinkType(null);
    setImageFile("");
    setUploadedImgPath("");
  };
  const getImgPath = () => {
    if (uploadedImgPath === "NotSet") return null;
    return (
      uploadedImgPath || (imageFile ? URL.createObjectURL(imageFile) : null)
    );
  };
  const { t } = useTranslation();
  return (
    <Stack direction="column" spacing={4} w={"full"}>
      {/* <Heading fontSize={"lg"}>1. 문제 질문</Heading> */}
      <Heading fontSize={"lg"}>
        {t("quizpang.quizbattlemanage.questioncard.text.question")}
      </Heading>
      <TextEditorForQuizpang
        style={{ height: "300px", overflow: "hidden" }}
        isFile={false}
        onChange={handleTextChange}
        value={quizTitleValue}
      />
      {
        <ImageMediaInput
          setImageFile={setImageFile}
          setLinkType={setLinkType}
          openModal={openModal}
          videoLink={videoLink}
          removeVideoLink={removeVideoLink}
          imgPath={getImgPath}
          removeImage={removeImage}
        />
      }
    </Stack>
  );
};

export default QuizTitle;

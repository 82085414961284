import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { deleteClassroomPromise, updateClassroomPromise } from "@store/actions";
import styles from "./CardClassroomTimer.module.scss";
import TimerImg from "@assets/images/icons/timer.png";
import moment from "moment";
import { getActiveClassroomPromise, setSocketData } from "@app/store/actions";
import Loading from "../Loading/Loading";
import { Button, HStack } from "@chakra-ui/react";
import { socketKind, socketUrl } from "@app/Constants/socketConstants";

const SECONDS = 1000;
const MINUTES = 60;
const added = 5;
const subed = -5;

const convertTimerToString = (time) => {
  const minute = Math.floor(time / MINUTES);
  const second = Math.floor(time) - minute * MINUTES;
  return `${minute}:${second.toString().padStart(2, "0")}`;
};

const ClassroomTimer = React.memo(() => {
  const dispatch = useDispatch();
  const activeClassroom = useSelector((state) => state.classrooms.classSession);
  const user = useSelector((state) => state.user);
  const [seconds, setSeconds] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (!activeClassroom) {
      return;
    }

    const timer = setInterval(() => {
      const diff = (new Date(activeClassroom.finishAt) - new Date()) / SECONDS;
      if (diff < 600) {
        setEditable(true);
      }
      if (diff > 600) {
        setEditable(false);
      }

      if (diff <= 0) {
        dispatch(deleteClassroomPromise(activeClassroom.classroomId)).then(
          () => {
            dispatch(
              setSocketData({
                method: "POST",
                uri: socketUrl.classroomSendImage,
                groupId: activeClassroom?.groupId,
                clientId: user.clientId,
                type: "CLASS_END",
                data: "",
              })
            );
            dispatch(getActiveClassroomPromise(user.clientId));
          }
        );
      }

      setSeconds(parseInt(Math.abs(diff)));
    }, 1000);
    return () => clearInterval(timer);
  }, [activeClassroom, dispatch, user.clientId]);

  const sendUpdatedClassroomBySocket = () => {
    console.log("sendUpdatedClassroomBySocket");
    if (!activeClassroom) return;
    dispatch(
      setSocketData({
        method: "POST",
        uri: "/classroom/sendImage",
        groupId: activeClassroom?.groupId,
        clientId: user?.clientId,
        type: "CLASS_UPDATE",
        data: "",
      })
    );
  };

  const sendAddMinutesBySocket = () => {
    console.log("sendAddMinutesBySocket");
    if (!activeClassroom) return;
    dispatch(
      setSocketData({
        method: "POST",
        uri: "/classroom/sendImage",
        groupId: activeClassroom?.groupId,
        clientId: user.clientId,
        type: "TIME_BUTTON_UPDATE",
        data: "",
      })
    );
  };

  const addMinutes = (minute) => {
    if (!activeClassroom) return;
    setLoading(true);
    setTimeout(() => {
      dispatch(
        updateClassroomPromise({
          classroomId: activeClassroom.classroomId,
          finishAt: moment(activeClassroom.finishAt)
            .add(minute, "minutes")
            .toISOString(),
        })
      ).then(() => {
        sendUpdatedClassroomBySocket();
        console.log("sendAddMinutesBySocket worked!");
        sendAddMinutesBySocket();
        console.log("sendAddMinutesBySocket worked!");
        setLoading(false);
      });
    }, 1000);
  };

  return (
    <HStack spacing="4">
      <Button
        onClick={() => {
          addMinutes(subed);
        }}
        _hover={{ backgroundColor: "#4684e7" }}
        isDisabled={editable}
      >
        {`${subed}`}
      </Button>
      <HStack spacing="2">
        <img src={TimerImg} alt="timer" width={24} height={24} />
        <span className="font-bold text-xl">
          {convertTimerToString(seconds)}
        </span>
      </HStack>
      <Button
        onClick={() => {
          addMinutes(added);
        }}
        _hover={{ backgroundColor: "#4684e7" }}
      >
        {`+${added}`}
      </Button>
      {loading && <Loading />}
    </HStack>
  );
});

export default ClassroomTimer;

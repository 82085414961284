import React, { useState, useEffect } from "react";
import {
  Select,
  Input,
  HStack,
  Heading,
  Stack,
  IconButton,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { getQuizCategory } from "@api";
import { CategoryModal } from "@app/pages/QuizPang/category-modal";
import { SubjectModal } from "@app/pages/QuizPang/subject-modal";
import { subjectStore } from "@app/pages/QuizPang/subject-store";
import { useTranslation } from "react-i18next";

const QuizCategory = ({
  quizLevel,
  setQuizLevel,
  grade,
  setGrade,
  subject,
  setSubject,
  selectPublisher,
  setSelectPublisher,
  keyword,
  setKeyword,
}) => {
  const [subjects, setSubjects] = useState([]);
  const [publishers, setPublishers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubjectModalOpen, setIsSubjectModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const refreshPublishers = async () => {
    try {
      const response = await getQuizCategory();
      if (response?.data.quizCategories) {
        const formattedPublishers = response.data.quizCategories.map(
          (category) => ({
            id: category.quizCategoryId,
            name: category.quizCategory,
          })
        );
        setPublishers(formattedPublishers);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleAddPublisher = async (newPublisher) => {
    await refreshPublishers();
    closeModal();
  };

  useEffect(() => {
    refreshPublishers();
  }, []);

  useEffect(() => {
    setSubjects(subjectStore.data);
  }, []);

  const handleAddSubject = (newSubject) => {
    subjectStore.add(newSubject);
    setSubjects(subjectStore.data);
    setIsSubjectModalOpen(false);
  };
  const { t } = useTranslation();

  const gradeString = {
    1: t("timetable.addtimetable.select.1stgrade"),
    2: t("timetable.addtimetable.select.2ndgrade"),
    3: t("timetable.addtimetable.select.3rdgrade"),
    4: t("timetable.addtimetable.select.4thgrade"),
    5: t("timetable.addtimetable.select.5thgrade"),
    6: t("timetable.addtimetable.select.6thgrade"),
  };
  return (
    <Stack direction="column" spacing={4} w={"full"}>
      {/* <Heading fontSize={"lg"}>6. 문제 카테고리 (선택)</Heading> */}
      <Heading fontSize={"lg"}>
        {t("quizpang.quizbattlemanage.questioncard.text.category")}
      </Heading>

      <HStack spacing={4}>
        <Select
          // placeholder="난이도 선택"
          placeholder={t("quizpang.quizbattlemanage.create.selectlevel")}
          value={quizLevel}
          onChange={(e) => setQuizLevel(e.target.value)}
        >
          {// ["쉬움", "보통", "어려움"]
          [
            t("timetable.quizpang.quizbattle.scoring.difficulty.easy"),
            t("timetable.quizpang.quizbattle.scoring.difficulty.medium"),
            t("timetable.quizpang.quizbattle.scoring.difficulty.hard"),
          ].map((level, index) => (
            <option key={index} value={index + 1}>
              {level}
            </option>
          ))}
        </Select>

        <Select
          // placeholder="학년 선택"
          placeholder={t("quizpang.quizbattlemanage.create.selectgrade")}
          value={grade}
          onChange={(e) => setGrade(e.target.value)}
        >
          {/* <option value={0}>학년무관</option> */}
          <option value={0}>{t("grade.none")}</option>
          {[1, 2, 3, 4, 5, 6].map((grade) => (
            <option key={grade} value={grade}>
              {gradeString[grade]}
            </option>
          ))}
        </Select>
      </HStack>

      <HStack spacing={4}>
        <Select
          // placeholder="과목 선택"
          placeholder={t(
            "quizpang.quizbattlemanage.questioncard.category.dropdown.choosesubject"
          )}
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        >
          {subjects.map((subject, index) => {
            return (
              <option key={index} value={subject}>
                {subject}
              </option>
            );
          })}
        </Select>
        <IconButton
          aria-label="과목 추가"
          icon={<AddIcon />}
          onClick={() => setIsSubjectModalOpen(true)}
        />
      </HStack>

      <HStack spacing={4}>
        <Select
          // placeholder="출판사 선택"
          placeholder={t("quizpang.quizbattlemanage.create.selectPublisher")}
          value={selectPublisher.id}
          onChange={(e) => {
            const selectedPublisher = publishers.find(
              (publisher) => publisher.id === e.target.value
            );
            setSelectPublisher(selectedPublisher ? selectedPublisher.name : "");
          }}
        >
          {publishers.map((publisher) => (
            <option key={publisher.id} value={publisher.id}>
              {publisher.name}
            </option>
          ))}
        </Select>

        <IconButton
          aria-label="출판사 추가"
          icon={<AddIcon />}
          onClick={openModal} // 모달 열기 함수 호출
        />

        <CategoryModal
          open={isModalOpen}
          onCancel={closeModal}
          onAdd={handleAddPublisher}
          refreshPublishers={refreshPublishers}
        />

        <SubjectModal
          open={isSubjectModalOpen}
          onCancel={() => setIsSubjectModalOpen(false)}
          onAdd={handleAddSubject}
        />
      </HStack>

      <Input
        // placeholder="키워드 (선택사항): 물리학 1, 물리학 2, ..."
        placeholder={t("quizpang.quizbattlemanage.create.keyword.placeholder")}
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
      />
    </Stack>
  );
};

export default QuizCategory;

import { getBackend } from "@api";
import { useSurveyset } from "@app/api/survey/hooks/useSurveyset";
import SurveyCard from "@app/components/Card/SurveyCard";
import { Button, Divider, SimpleGrid, Stack, Text } from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const SurveyList = () => {
  const user = useSelector((state) => state.user);

  const { groupId } = useParams();
  const { ref, inView } = useInView();

  const [orderType, setOrderType] = useState("DATE");
  const [orderBy, setOrderBy] = useState("DESC");

  const [quizsetSurveys, setQuizsetSurveys] = useState([]);
  const [quizsetSurveysNext, setQuizsetSurveysNext] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const getQuizsetServeys = async () => {
    try {
      const { data } = await getBackend(
        `quizset/all?index=${
          quizsetSurveysNext ? quizsetSurveysNext : 0
        }&quizsetType=SURVEY`
      );
      setIsLoading(false);
      setQuizsetSurveys(data.quizsets);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getQuizsetServeys();
  }, []);

  const {
    data: surveysetData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useSurveyset(user.clientId, "SURVEY", orderType, orderBy);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  console.log("quizsetSurveys", quizsetSurveys);

  return (
    <>
      <Stack w="full">
        <Text fontSize="2xl" fontWeight="bold" textAlign="center">
          전체 설문지
        </Text>
        <SimpleGrid
          columns={{
            base: 1,
            md: 2,
            lg: 3,
            xl: 4,
          }}
          spacing={10}
          w={"full"}
        >
          {quizsetSurveys.map((quizset) => {
            return <SurveyCard quizset={quizset} groupId={groupId} />;
          })}
        </SimpleGrid>
        <Button
          onClick={() => {
            // quizsetSurveysNext
            //   ? setQuizsetSurveysNext(quizsetSurveysNext + 1)
            //   : setQuizsetSurveysNext(1);
            // getQuizsetServeys();

            //최대 9까지

            if (quizsetSurveysNext < 10) {
              setQuizsetSurveysNext(quizsetSurveysNext + 1);
              getQuizsetServeys();
            }
          }}
        >
          더 불러오기
        </Button>
      </Stack>
      <Divider w={"full"} color="gray.200" />

      <Text fontSize="2xl" fontWeight="bold" textAlign="center">
        나의 설문지
      </Text>
      <SimpleGrid
        columns={{
          base: 1,
          md: 2,
          lg: 3,
          xl: 4,
        }}
        spacing={10}
        w={"full"}
      >
        {surveysetData?.pages.flatMap((page) =>
          page.data.data
            .filter(
              (quizset, index, self) =>
                self.findIndex((q) => q.quizsetId === quizset.quizsetId) ===
                index
            )
            .map((quizset) => {
              return <SurveyCard quizset={quizset} groupId={groupId} />;
            })
        )}
      </SimpleGrid>
      {hasNextPage && <div ref={ref} />}
    </>
  );
};

export default SurveyList;

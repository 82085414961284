import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

// Pages Component
import Home from "@pages/Home";
import SignIn from "@pages/Auth";
import AuthManager from "@managers/AuthManager";
import Settings from "@pages/Settings";
import Renewal from "@pages/Auth/renewal";
import NewClassroom from "@app/pages/NewClassroom/index.jsx";
import Dev from "@app/pages/Dev/index.js";
import QuizCreateHome from "@app/pages/QuizManagement/QuizCreateHome.jsx";
import SurveyHome from "@app/pages/Survey/index.jsx";
import ManagingWrapper from "@app/pages/Managing/ManagingWrapper.jsx";

const Assignment = lazy(() => import("../pages/Assignment/index.js"));
const QuizManagement = lazy(() => import("../pages/QuizManagement/index.jsx"));
const Quizpang = lazy(() => import("../pages/QuizPang/index.js"));
const QuizsetNew = lazy(() =>
  import("../pages/QuizPang/QuizsetNew/quizset-new.js")
);
const QuizsetDetailInClass = lazy(() =>
  import("../pages/QuizPang/InClass/quizset-detail.js")
);
const QuizPangInClass = lazy(() =>
  import("../pages/QuizPang/InClass/index.js")
);
const QuizsetReady = lazy(() =>
  import("../pages/QuizPang/QuizReady/quizset-ready.js")
);
const QuizsetSession = lazy(() =>
  import("../pages/QuizPang/QuizSession/quizset-session.js")
);
const QuizsetScoring = lazy(() =>
  import("../pages/QuizPang/InClass/quizset-scoring.js")
);
const QuizpangCommunity = lazy(() =>
  import("../pages/QuizPang/Community/index.js")
);
const Attendance = lazy(() => import("../pages/Attendance/index.js"));
const ClassroomDashboard = lazy(() => import("../pages/Dashboard/index.js"));
const QuizsetSurveyReady = lazy(() =>
  import("../pages/QuizPang/QuizReady/quizset-survey-ready.js")
);
const QuizsetSurveyBundleReady = lazy(() =>
  import("../pages/QuizPang/QuizReady/quizset-survey-bundle-ready.jsx")
);
const QuizsetSurveyBundleReadyDetail = lazy(() =>
  import("../pages/QuizPang/QuizReady/quizset-survey-bundle-ready-detail.jsx")
);
const Classroom = lazy(() => import("../pages/Classroom/index.js"));
const GroupActivity = lazy(() => import("../pages/GroupActivity/index.js"));
const Students = lazy(() => import("../pages/Students/index.js"));
const AddClassroom = lazy(() => import("../pages/Classroom/add-classroom.js"));
const AddStudents = lazy(() => import("../pages/Classroom/add-students.js"));
const Managing = lazy(() => import("../pages/Managing/index.jsx"));
const Notice = lazy(() => import("../pages/Notice/index.js"));
const OtherSchool = lazy(() =>
  import("../pages/QuizPang/OtherSchool/index.js")
);
const EditClassroom = lazy(() =>
  import("../pages/Classroom/edit-classroom.js")
);
//const ThumbnailClassroom = lazy(() =>
//  import('../pages/Classroom/thumbnail-classroom.js'),
//);

const authProtectedRoutes = [
  { path: "/home", component: Home },
  { path: "/add-students", component: AddStudents },
  { path: "/add-classroom", component: AddClassroom },
  { path: "/edit-classroom", component: EditClassroom },
  { path: "/classroom/:groupId", component: NewClassroom },
  { path: "/group-activity/:groupId", component: GroupActivity },
  { path: "/students/:groupId", component: Students },
  { path: "/auth", component: SignIn },
  { path: "/auth-manager", component: AuthManager },
  { path: "/settings", component: Settings },
  { path: "/renewal", component: Renewal },
  // 퀴즈팡 관리
  { path: "/quiz-pang", component: Quizpang },
  { path: "/quiz-pang/other-school", component: OtherSchool },
  // 퀴즈셋 리스트
  {
    path: "/quiz-pang/:groupId/quizset-detail/:quizsetId",
    component: QuizsetDetailInClass,
  },
  // 퀴즈팡 리스트
  { path: "/quiz-pang/:groupId", component: QuizPangInClass },
  // 퀴즈팡 준비
  {
    path: "/quiz-pang/:groupId/quizset-ready/:quizsetId",
    component: QuizsetReady,
  },
  // 설문지 리스트
  {
    path: "/survey/:groupId",
    component: SurveyHome,
  },
  // 설문 준비
  {
    path: "/survey/:groupId/quizset-survey-ready/:quizsetId",
    component: QuizsetSurveyReady,
  },
  // 설문지 묶음 준비
  {
    path: "/quiz-pang/:groupId/quizset-survey-bundle-ready/:quizsetId",
    component: QuizsetSurveyBundleReady,
  },

  // 설문지 묶음 준비 > 설문 묶음 시작
  {
    path: "/quiz-pang/:groupId/quizset-survey-bundle-ready/:quizsetId/detail",
    component: QuizsetSurveyBundleReadyDetail,
  },
  // 퀴즈팡 결과
  {
    path:
      "/quiz-pang/:groupId/quizset-detail/:quizsetId/session/:sessionId/scoring",
    component: QuizsetScoring,
  },
  // 퀴즈팡 진행 중
  {
    path: "/quiz-pang/:groupId/session/:sessionId/:result?",
    component: QuizsetSession,
  },
  // 새로운 퀴즈팡 관리
  { path: "/quiz-management", component: QuizManagement },
  // 퀴즈팡 관리 > 퀴즈셋 관리
  {
    path: "/quiz-management/:type/:quizsetId",
    component: QuizCreateHome,
  },
  // 출석부
  { path: "/attendance", component: Attendance },
  // 공지사항
  { path: "/notice/:groupId", component: Notice },
  // 과제관리
  { path: "/assignment/:groupId", component: Assignment },
  // 임시 수업 시간 외 사용 관리 Path
  { path: "/managing", component: Managing },
  { path: "/managing/:groupId", component: ManagingWrapper },
  { path: "/students-managed/:groupId", component: Students },
  // 퀴즈팡 테스트
  { path: "/test-quiz", component: QuizpangCommunity },
  // dev
  { path: "/dev", component: Dev },
  // 대시보드
  { path: "/dashboard/:groupId", component: ClassroomDashboard },
  {
    path: "/",
    exact: true,
    component: () => (
      <Redirect
        to={{
          pathname: "/auth-manager",
        }}
      />
    ),
  },
];
const publicRoutes = [
  { path: "/classroom", component: Classroom },
  // { path: "/dev", component: Dev },
];

export { authProtectedRoutes, publicRoutes };

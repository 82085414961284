import { profileImgPath } from "@api";
import React, { useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import ProfileImage from "../ProfileImage";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { setCommentsSendClient } from "@app/store/actions";
import {
  selectActiveClassroom,
  selectStudentsWithGroupActivity,
  selectSubroupActivity,
} from "@app/store/selectors";
import { useTranslation } from "react-i18next";

export const CommentsLayerStudent = ({ onClosed }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { groupId } = useSelector((state) => selectActiveClassroom(state));
  const studentList = useSelector((state) =>
    selectStudentsWithGroupActivity(state, groupId)
  );
  const { screenSortOption } = useSelector((state) => state.classrooms);
  const { selectedClientIds } = useSelector((state) => state.commentsLayer);

  const subgroupList = useSelector(selectSubroupActivity);
  const subgroupListWithNoGroup = useMemo(() => {
    if (!screenSortOption || screenSortOption !== 3) {
      return [
        {
          name: "미그룹",
          students: studentList,
        },
      ];
    }
    const noGroupStudent = studentList.filter((student) => !student.subgroupId);
    if (noGroupStudent.length) {
      return [
        ...subgroupList,
        {
          name: "미그룹",
          students: noGroupStudent,
        },
      ];
    }
    return subgroupList;
  }, [subgroupList, studentList, screenSortOption]);

  const isActivated = useCallback(
    (students) =>
      students
        .map((student) => student.clientId)
        .every((v) => selectedClientIds.includes(v)),
    [selectedClientIds]
  );

  const onStudentClick = (studentClientId) => {
    dispatch(
      setCommentsSendClient(
        selectedClientIds.includes(studentClientId)
          ? selectedClientIds.filter((item) => item !== studentClientId)
          : [...selectedClientIds, studentClientId]
      )
    );
  };
  const onActivityGroupClick = (group) => {
    let filterList = [];
    const groupClientIds = group.students.map((student) => student.clientId);
    if (isActivated(group.students)) {
      filterList = selectedClientIds.filter(
        (id) => !groupClientIds.includes(id)
      );
    } else {
      filterList = [...new Set([...selectedClientIds, ...groupClientIds])];
    }
    dispatch(setCommentsSendClient(filterList));
  };

  return (
    <>
      <StyledDimmed onClick={() => onClosed()} />
      <StyledStudentLayer>
        <StyledCloseIcon fontSize="large" onClick={() => onClosed()} />
        <StyledGroupScrollWrap>
          {subgroupListWithNoGroup.map((group) => (
            <StyledGroupWrap key={group.subgroupId}>
              <StyledGroupTitle>
                {subgroupListWithNoGroup.length === 1
                  ? // ? "학생 펜 보기"
                    t("timetable.focustime.screen.comment.studentpen")
                  : group.name}
                {/* <Tooltip title='학생 펜 모두 보기 또는 모두 숨기기' arrow > */}
                <StyledGroupSelectedButton
                  onClick={() => onActivityGroupClick(group)}
                >
                  {/* 모두
                  {isActivated(group.students) ? " 숨기기" : " 보기"} */}
                  {isActivated(group.students)
                    ? t("timetable.focustime.screen.comment.hideall")
                    : t("timetable.focustime.screen.comment.showall")}
                </StyledGroupSelectedButton>
                {/* </Tooltip> */}
              </StyledGroupTitle>
              <StyledStudentWrap>
                {group.students.map((student) => (
                  <StyledStudentItem
                    key={`student-${student.clientId}`}
                    onClick={() => onStudentClick(student.clientId)}
                  >
                    <StyledBackground
                      $isActive={selectedClientIds.includes(student.clientId)}
                    >
                      <ProfileImage
                        size={24}
                        url={
                          student?.clientId
                            ? profileImgPath(student.clientId)
                            : null
                        }
                      />
                      <span>{student.userName}</span>
                    </StyledBackground>
                  </StyledStudentItem>
                ))}
              </StyledStudentWrap>
            </StyledGroupWrap>
          ))}
        </StyledGroupScrollWrap>
      </StyledStudentLayer>
    </>
  );
};
const StyledStudentLayer = styled.div`
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  background-color: white;
  box-shadow: 0px 4px 16px 4px rgb(110 110 110 / 5%);
  border-radius: 6px;
  width: 80%;
  height: calc(100% - 80px);
  cursor: auto;
`;
const StyledDimmed = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  cursor: pointer;
`;
const StyledGroupScrollWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 12px;
  overflow-y: auto;
`;
const StyledGroupWrap = styled.div`
  padding: 20px 0;

  & + & {
    border-top: 1px solid #dedede;
  }
`;
const StyledGroupTitle = styled.div`
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: bold;
`;
const StyledStudentWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const StyledGroupSelectedButton = styled.button`
  background-color: #0894a0;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  margin-left: 8px;
  font-size: 12px;
`;
const StyledStudentItem = styled.div`
  flex: 0 1 20%;
  padding: 4px;
`;
const StyledBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 8px 4px;
  background: #969696;
  border-radius: 8px;
  color: white;
  font-size: 12px;

  span {
    display: inline-block;
    margin-top: 8px;
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      background: #0894a0;
    `}
`;
const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 4px;
  color: black;
  cursor: pointer;
`;

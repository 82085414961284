import React, { Fragment } from "react";
import SidebarClassroom from "./SidebarClassroom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Divider,
  HStack,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

const ClassroomWrapper = ({
  children,
  buttons,
  prevPageName,
  prevPageRoute,
  currentPageName,
}) => {
  return (
    <SidebarClassroom>
      <Container maxW="full" minH={"100vh"} p="8" bg={"white"}>
        <Stack direction={"column"} spacing={4} w={"full"}>
          <HStack w={"full"} spacing={4} mt={1}>
            <Breadcrumb>
              {prevPageName && (
                <BreadcrumbItem>
                  <BreadcrumbLink href={prevPageRoute}>
                    <Text fontSize={"3xl"}>{prevPageName}</Text>
                  </BreadcrumbLink>
                </BreadcrumbItem>
              )}
              <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink>
                  <Text fontSize={"3xl"} fontWeight={"bold"}>
                    {currentPageName}
                  </Text>
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            <HStack spacing={2}>
              {buttons &&
                buttons.map((item, index) => (
                  <Fragment key={index}>{item}</Fragment>
                ))}
            </HStack>
          </HStack>
          <Divider w={"full"} color="gray.200" mt={2} />
          {children}
        </Stack>
      </Container>
    </SidebarClassroom>
  );
};

export default ClassroomWrapper;

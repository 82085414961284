import { gradeList } from "@app/Constants/groupTimetable";
import { Dropdown } from "../Modal/ModalEditClassTimetable";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import {
  createGroupPromise,
  createMembershipPromise,
  getMembershipByClientPromise,
  setReportLog,
  setSocketData,
  updateGroupPromise,
} from "@app/store/actions";
import { ButtonConstants } from "@app/Constants/ButtonConstants";
import { t } from "i18next";

const ModalAddManage = ({
  onHide,
  isUpdateModal,
  managingGroupForUpdate,
  setManagingGroupForUpdate,
  ...props
}) => {
  const dispatch = useDispatch();
  const { schoolId } = useSelector((state) => state.user);
  const user = useSelector((state) => state.user);

  const [grade, setGrade] = useState();
  const [groupName, setGroupName] = useState();

  const createNewManageGroup = async () => {
    if (!grade || !groupName) {
      alert("학년과 자기관리 그룹 이름을 지어주세요.");
      return;
    }
    const groupData = {
      schoolId: schoolId,
      state: "CREATED",
      groupType: "AFTER",
      subjectName: groupName,
      groupName: "1",
      grade: grade,
    };

    await dispatch(createGroupPromise(groupData)).then(async (createdGroup) => {
      dispatch(
        createMembershipPromise({
          groupId: createdGroup.groupId,
          clientId: user.clientId,
          role: "TEACHER",
        })
      );
      await dispatch(getMembershipByClientPromise(user.clientId));
    });
  };

  const updateManageGroup = async () => {
    if (!grade || !groupName) {
      alert("학년과 자기관리 그룹 이름을 지어주세요.");
      return;
    }
    const updatedGroupData = {
      groupId: managingGroupForUpdate.groupId,
      groupType: "AFTER",
      subjectName: groupName,
      groupName: "1",
      grade: grade,
    };
    dispatch(updateGroupPromise(updatedGroupData)).then(() => {
      dispatch(
        setSocketData({
          method: "POST",
          uri: "/classroom/sendImage",
          groupId: managingGroupForUpdate?.groupId,
          clientId: user.clientId,
          type: "MANAGE_GROUP_UPDATE",
          data: "",
        })
      );
      alert("수정을 완료하였습니다.");
    });
  };

  useEffect(() => {
    setGrade(managingGroupForUpdate?.grade.toString());
  }, [managingGroupForUpdate]);

  return (
    <Dialog
      open={props.show}
      onClose={() => {
        onHide();
        setGrade();
        setGroupName();
        setManagingGroupForUpdate();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle>
        {/* 수업 시간 외 앱/웹 관리 그룹을 {isUpdateModal ? '수정' : '추가'}합니다. */}
        {isUpdateModal
          ? t("webapplimit.modal.text.title.modify")
          : t("webapplimit.modal.text.title.add")}
      </DialogTitle>
      <DialogContent dividers>
        <Stack direction="column" spacing={2}>
          <DialogContentText>
            {/* 학년 변경 또는 학년을 {isUpdateModal ? "수정" : "입력"}해주세요. */}
            {isUpdateModal
              ? t("webapplimit.modal.form.label.modify.grade")
              : t("webapplimit.modal.form.label.add.grade")}
          </DialogContentText>
          <Dropdown
            // title="학년"
            title={t("timetable.addtimetable.text.grade")}
            name="grade"
            value={grade}
            onChange={(e) => setGrade(e.target.value)}
            data={gradeList}
          />
          <DialogContentText>
            {/* {isUpdateModal && "관리 그룹명 변경  또는 "}{" "}
            {!isUpdateModal && "관리"} 그룹명을{" "}
            {isUpdateModal ? "수정" : "입력"}
            해주세요. */}
            {isUpdateModal
              ? t("webapplimit.modal.form.label.groupname.modify")
              : t("webapplimit.modal.form.label.groupname.add")}
          </DialogContentText>
          <TextField
            variant="outlined"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            fullWidth
            inputProps={{
              maxLength: 25,
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onHide();
            setGrade();
            setGroupName();
            setManagingGroupForUpdate();
            dispatch(
              setReportLog(
                ButtonConstants.SELF_MANAGING.CANCEL_SELF_MANAGING_GROUP
              )
            );
          }}
          variant="contained"
        >
          {/* 취소 */}
          {t("webapplimit.modal.form.button.cancel")}
        </Button>
        <Button
          onClick={() => {
            {
              isUpdateModal ? updateManageGroup() : createNewManageGroup();
            }
            setGrade();
            setGroupName();
            onHide();
            setManagingGroupForUpdate();
            dispatch(
              setReportLog(
                ButtonConstants.SELF_MANAGING.SAVE_SELF_MANAGING_INFO
              )
            );
          }}
          variant="contained"
          style={{ minWidth: "max-content" }}
        >
          {/* {isUpdateModal ? "수정" : "추가"} */}
          {isUpdateModal
            ? t("webapplimit.modal.form.button.modify")
            : t("webapplimit.modal.form.button.add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAddManage;

import React from "react";
import QuizTypeWrapper from "./Common/QuizTypeWrapper";
import { HStack, Heading, Input } from "@chakra-ui/react";
import { TextEditorForQuizpang } from "../Input/TextEditorForQuizpang";
import { useTranslation } from "react-i18next";

const QuizTypeLongInput = ({ sampleAnswer, setSampleAnswer }) => {
  const { t } = useTranslation();
  return (
    <QuizTypeWrapper>
      {/* <Heading fontSize={"lg"}>3. 모범답안</Heading> */}
      <Heading fontSize={"lg"} textTransform={"capitalize"}>
        3.{t("timetable.quizpang.quizbattle.scoring.sampleanswer")}
      </Heading>
      <TextEditorForQuizpang
        onChange={setSampleAnswer}
        value={sampleAnswer}
        style={{ height: "150px" }}
        isFile={false}
      />
    </QuizTypeWrapper>
  );
};

export default QuizTypeLongInput;

import { Box, HStack, Heading, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

const ModalClassManagementAddAppHeader = ({ title }) => {
  const { t } = useTranslation();
  return (
    <Stack direction="column" spacing="1" justifyContent="flex-start">
      <Heading fontSize="md">
        ・{" "}
        {title
          ? title
          : t("timetable.focustime.accordion.chooseapp.text.media")}
      </Heading>
      <Box
        bg="white"
        border="1px"
        borderColor="gray.200"
        borderRadius="lg"
        p="2"
      >
        <HStack w="full" justifyContent="space-between" spacing={4}>
          <Text fontWeight="bold" fontSize="sm" w="full" textAlign="center">
            {/* 이름 */}
            {t("timetable.focustime.accordion.chooseapp.text.name")}
          </Text>
          <HStack spacing={2} w="full">
            <Text fontWeight="bold" fontSize="sm" w="full" textAlign="center">
              {/* 제어 가능한 OS */}
              {t("timetable.focustime.accordion.chooseapp.text.controllableos")}
            </Text>
          </HStack>
        </HStack>
      </Box>
    </Stack>
  );
};

export default ModalClassManagementAddAppHeader;

import { extendTheme } from "@chakra-ui/react";

export const chakraTheme = extendTheme({
  colors: {
    endingNavyBlue: {
      50: "#F2F3F4",
      100: "#E4E5E8",
      200: "#C6C8CE",
      300: "#A3A7B1",
      400: "#747C8D",
      500: "#1A365D",
      600: "#173053",
      700: "#142A48",
      800: "#10223B",
      900: "#0C182A",
    },
    blackPanther: {
      50: "#F3F3F3",
      100: "#E6E6E6",
      200: "#CACACA",
      300: "#A9A9A9",
      400: "#808080",
      500: "#424242",
      600: "#3B3B3B",
      700: "#333333",
      800: "#2A2A2A",
      900: "#1E1E1E",
    },
    dark: {
      50: "#F2F2F2",
      100: "#E4E5E5",
      200: "#C6C7C7",
      300: "#A3A3A5",
      400: "#747679",
      500: "#1A202C",
      600: "#171D27",
      700: "#141922",
      800: "#10141C",
      900: "#0C0E14",
    },
    seljukBlue: {
      50: "#F3F6FE",
      100: "#E7EDFD",
      200: "#CBD9FB",
      300: "#ACC3FA",
      400: "#85AAF8",
      500: "#4C8DF6",
      600: "#447EDC",
      700: "#3B6DBF",
      800: "#30599C",
      900: "#223F6E",
    },
    lightBlue: {
      100: "#FDFEFF",
      200: "#FCFDFF",
      300: "#F8FBFF",
      400: "#F5FAFF",
      500: "#F1F8FF",
      600: "#EEF6FF",
      700: "#D5DCE4",
      800: "#B8BFC6",
      900: "#979CA1",
      1000: "#6A6E72",
    },
    royalStar: {
      50: "#FFFCF3",
      100: "#FFF9E7",
      200: "#FEF3CC",
      300: "#FEECAD",
      400: "#FDE687",
      500: "#FDDF50",
      600: "#E2C748",
      700: "#C4AD3E",
      800: "#A08D33",
      900: "#716424",
    },
    forgottenPurple: {
      50: "#F7F5FD",
      100: "#EFEBFB",
      200: "#DED4F7",
      300: "#CBBBF3",
      400: "#B79EEE",
      500: "#A07BEA",
      600: "#8F6ED1",
      700: "#7C5FB5",
      800: "#654E94",
      900: "#483769",
    },
    georgiaPeach: {
      50: "#FFF5F5",
      100: "#FEEAEA",
      200: "#FED2D2",
      300: "#FDB8B8",
      400: "#FD9999",
      500: "#FC7272",
      600: "#E16666",
      700: "#C35858",
      800: "#9F4848",
      900: "#713333",
    },
    astroArcadeGreen: {
      50: "#F5FFF5",
      100: "#EAFEEA",
      200: "#D3FED2",
      300: "#B9FDB8",
      400: "#9BFD99",
      500: "#75FC72",
      600: "#69E166",
      700: "#5BC358",
      800: "#4A9F48",
      900: "#347133",
    },
  },
});

export default chakraTheme;

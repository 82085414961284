import {
  Box,
  Divider,
  HStack,
  Icon,
  SimpleGrid,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { PiPlugsConnectedBold } from "react-icons/pi";
import { PiPlugsBold } from "react-icons/pi";
import StudentThumnail from "./StudentThumnail";
import { selectActiveClassroom } from "@app/store/selectors";
import { useSelector } from "react-redux";
import { useParticipationByClassroom } from "../QuizPang/hooks/useParticipation";
import ModalStudentFullScreen from "@app/components/Modal/ModalStudentFullScreen";
import { useTranslation } from "react-i18next";
import { IoGridOutline } from "react-icons/io5";

const StudentThumnailList = ({
  colums,
  studentList,
  setSelectedStudent,
  selectedStudent,
  setSelectedImage,
  selectedImage,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  const { images: stdImages } = useSelector((state) => state.control);

  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const [sliderColumnNumber, setSliderColumnNumber] = useState(1);

  const participationData = useParticipationByClassroom(
    activeClassroom?.classroomId
  );

  if (participationData.isLoading) {
    return <div>로딩중</div>;
  }

  const participationStudents = participationData.participations;

  // participationStudents의 배열의 학생 리스트를 비교해서 state가 ATTEND인 학생만 뽑아낸 후 studentList에서 같은 clientId를 가진 학생을 분류해주는 함수
  const getParticipationStudents = (studentList) => {
    const result = [];
    studentList.forEach((student) => {
      participationStudents?.forEach((participationStudent) => {
        if (
          student.clientId === participationStudent.clientId &&
          participationStudent.state === "ATTEND"
        ) {
          result.push(student);
        }
      });
    });
    return result;
  };

  const participationStudentList = getParticipationStudents(studentList);

  // Attend가 아닌 학생들을 뽑아내는 함수
  const getOfflineStudents = (studentList) => {
    const result = [];
    studentList.forEach((student) => {
      participationStudents?.forEach((participationStudent) => {
        if (
          student.clientId === participationStudent.clientId &&
          participationStudent.state !== "ATTEND"
        ) {
          result.push(student);
        }
      });
    });
    return result;
  };

  const offlineStudents = getOfflineStudents(studentList);

  return (
    <Stack direction="column" w="full" spacing={4}>
      <ModalStudentFullScreen
        isOpen={isOpen}
        onClose={onClose}
        participationStudentList={participationStudentList}
        offlineStudents={offlineStudents}
      />
      <Tabs variant={"line"}>
        <TabList>
          <Tab>
            <HStack spacing={2}>
              <Icon as={PiPlugsConnectedBold} />
              <Text>
                {/* 온라인 */}
                {t("timetable.focustime.studentlist.online")}
              </Text>
              <Text fontSize="lg" fontWeight="bold">
                {participationStudentList?.length}
                {t("focustime.text.people")}
              </Text>
            </HStack>
          </Tab>
          <Tab>
            <HStack spacing={2}>
              <Icon as={PiPlugsBold} />
              <Text>
                {/* 오프라인 */}
                {t("timetable.focustime.studentlist.offline")}
              </Text>
              <Text fontSize="lg" fontWeight="bold">
                {offlineStudents?.length}
                {t("focustime.text.people")}
              </Text>
            </HStack>
          </Tab>
        </TabList>

        <Box p={4}>
          <Slider
            aria-label="grid-column-slice"
            min={1}
            max={5}
            defaultValue={1}
            onChange={(val) => setSliderColumnNumber(val)}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb boxSize={8}>
              <Box color="#3b82f6" as={IoGridOutline} />
            </SliderThumb>
          </Slider>
        </Box>

        <TabPanels>
          <TabPanel>
            <Stack direction="column">
              <SimpleGrid
                columns={{ sm: 1, md: sliderColumnNumber }}
                spacing={2}
              >
                {participationStudentList?.map((student, index) => (
                  <StudentThumnail
                    key={index}
                    student={student}
                    images={stdImages}
                    w="full"
                    h="full"
                    colums={colums}
                    state="online"
                    setSelectedStudent={setSelectedStudent}
                    selectedStudent={selectedStudent}
                    setSelectedImage={setSelectedImage}
                    selectedImage={selectedImage}
                    sliderColumnNumber={sliderColumnNumber}
                  />
                ))}
              </SimpleGrid>
              <Divider w="full" color="gray.300" />
            </Stack>
          </TabPanel>
          <TabPanel>
            <Stack direction="column">
              <SimpleGrid
                gap={4}
                // overflowX={"scroll"}
                columns={{ sm: 1, md: sliderColumnNumber }}
              >
                {offlineStudents?.map((student) => (
                  <StudentThumnail
                    key={student.clientId}
                    student={student}
                    w="full"
                    h="full"
                    colums={colums}
                    state="offline"
                    sliderColumnNumber={sliderColumnNumber}
                  />
                ))}
              </SimpleGrid>
            </Stack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
};

export default StudentThumnailList;

import ICONS from "@app/assets/images/appIcons";
import imgNotiIcon from "@images/home/img_notice.png";
import { useEffect, useState } from "react";
import styles from "./CardManagingControlTest.module.scss";
import { useTranslation } from "react-i18next";
import i18next, { t } from "i18next";

const CardManagingControlTest = ({
  newProgramIdsInWatchdogsManaging,
  setNewProgramIdsInWatchdogsManaging,
  watchdog,
  name,
  alias,
  setProgramConfigData,
  programConfigData,
}) => {
  const programId = watchdog.programId;
  const { i18n } = useTranslation();
  alert(i18n.language);
  return (
    <div name="CardClassManagementsSmall" className={styles["applist"]}>
      <div className={styles["list-box"]}>
        <div className={styles["icon-wrap"]}>
          <img
            className={styles["icon"]}
            src={ICONS[alias] || imgNotiIcon}
            alt="icon"
          />
          <span>{name}</span>

          <div className={styles["dropdown-btn-wrap"]}>
            <button
              className={styles["hours-button"]}
              onClick={() => {
                setProgramConfigData({
                  ...programConfigData,
                  [programId]: programConfigData[programId] - 1,
                });
              }}
            >
              -
            </button>
            <div>
              {programConfigData[programId] <= 0 ||
              !programConfigData[programId]
                ? i18n.language === "ko"
                  ? "사용못함"
                  : "Not available"
                : i18n.language === "ko"
                ? programConfigData[programId] + " 시간"
                : programConfigData[programId] +
                  i18next.t("hour", {
                    count: programConfigData[programId],
                  })}
            </div>
            <button
              className={styles["hours-button"]}
              onClick={() => {
                if (programConfigData[programId] === NaN) {
                  return;
                }
                setProgramConfigData({
                  ...programConfigData,
                  [programId]: programConfigData[programId] + 1,
                });
              }}
            >
              +
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardManagingControlTest;

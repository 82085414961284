import { Heading, Stack } from "@chakra-ui/react";
import React from "react";
import { TextEditorForQuizpang } from "../Input/TextEditorForQuizpang";
import { useTranslation } from "react-i18next";

const QuizExplanation = ({ quizExplanationValue, onChangeQuizExplanation }) => {
  const handleTextChange = (newValue) => {
    onChangeQuizExplanation(newValue);
  };
  const { t } = useTranslation();
  return (
    <Stack direction="column" spacing={4} w={"full"}>
      {/* <Heading fontSize={"lg"}>5. 문제 해설 (선택)</Heading> */}
      <Heading fontSize={"lg"}>
        {t("quizpang.quizbattlemanage.questioncard.text.explanation")}
      </Heading>
      <TextEditorForQuizpang
        style={{ height: "150px", overflow: "hidden" }}
        isFile={false}
        onChange={handleTextChange}
        value={quizExplanationValue}
      />
    </Stack>
  );
};

export default QuizExplanation;

import React, { useState } from "react";
import CardLectureInfo from "@components/Card/CardLectureInfo";
import CardLectureInfoEmpty from "@components/Card/CardLectureInfo_Empty";
import { useSelector } from "react-redux";
import { selectAllTimetables } from "@store/selectors";
import { selectAllMyGroups } from "@app/store/groups/selector";
import { useTimetableByClientId } from "@app/pages/Home/hooks/useTimetable";
import {
  classifyTimetable,
  getDayToKorean,
} from "@app/utils/timetable/timetableUtils";
import { ModalEditTimetable } from "../Modal/Timetable/ModalEditTimetable";
import { ModalCreateTimetable } from "../Modal/Timetable/MocalCreateTimetable";
import { Button } from "@mui/material";
import {
  Container,
  GridItem,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  Grid,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

const ClassList = () => {
  const { t } = useTranslation();

  const user = useSelector((state) => state.user);
  const allMyGroups = useSelector((state) => selectAllMyGroups(state));
  const allTimetables = useSelector((state) => selectAllTimetables(state));

  const [createModalShow, setCreateModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [formData, setFormData] = useState();

  const getTimetableData = useTimetableByClientId(user.clientId);

  const handleCreateModal = ({ timetables, weekDay, period = "" }) => {
    setFormData({
      timetables: [],
      timetableId: "",
      weekDay,
      period,
      subjectName: "",
      groupName: "",
      grade: "",
      groupId: "",
      startTime: "",
      endTime: "",
      isEdit: false,
    });
    setCreateModalShow(true);
  };

  if (
    (createModalShow && getTimetableData.isError) ||
    getTimetableData.isLoading
  ) {
    return (
      <>
        {createModalShow && (
          <ModalCreateTimetable
            show={createModalShow}
            onHide={() => setCreateModalShow(false)}
            timetableData={formData}
          />
        )}
      </>
    );
  }

  if (getTimetableData.isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-2xl font-bold">시간표를 불러오고 있습니다...</div>
      </div>
    );
  }

  if (getTimetableData.isError) {
    return (
      <>
        <div className="flex flex-col items-center justify-center h-screen">
          <div className="text-2xl font-bold mb-4">
            생성된 시간표가 없는것 같아요. <br />
            아래의 시간표 생성 버튼을 눌러 시간표를 생성해보세요!
          </div>
          <div>
            <Button
              variant="contained"
              onClick={() => {
                handleCreateModal({ timetables: [], weekDay: 1, period: 1 });
              }}
            >
              시간표 생성
            </Button>
          </div>
        </div>
      </>
    );
  }

  const timetableData = getTimetableData.timetable.data;

  const classifiedTimetable = classifyTimetable(timetableData);

  const handleEditModal = ({
    timetables,
    weekDay,
    period,
    startTime,
    endTime,
    group,
    timetableId,
  }) => {
    setFormData({
      timetables: allTimetables.filter(
        (timetable) => timetable.groupId === group.groupId
      ),
      timetableId,
      weekDay: weekDay,
      period,
      groupType: group.groupType,
      subjectName: group.subjectName,
      groupName: group.groupName,
      startTime,
      endTime,
      grade: group.grade,
      groupId: group.groupId,
      isEdit: true,
    });
    setEditModalShow(true);
  };

  return (
    <Container maxW="full" pt={2} pb={8} px={1}>
      <SimpleGrid
        columns={{
          base: 1,
          md: 1,
          lg: 5,
          xl: 5,
        }}
        spacing={4}
      >
        {Object.entries(classifiedTimetable).map(
          ([weekDay, timeTables], index) => {
            var timeTables2 = [];
            var previousClass = 0;
            for (var i = 0; i < timeTables.length; i++) {
              var thisEntry = timeTables[i];
              var currentClass = thisEntry.period;
              while (previousClass + 1 < currentClass) {
                var timeTableObjectKey = weekDay + "-" + (previousClass + 1);
                var emptyEntry = {
                  timetableId: timeTableObjectKey,
                  groupId: null,
                  period: previousClass + 1,
                  startTime: "",
                  endTime: "",
                };
                timeTables2.push(emptyEntry);
                previousClass = previousClass + 1;
              }
              timeTables2.push(thisEntry);
              previousClass = currentClass;
            }
            var maxClass = 8;
            while (previousClass + 1 <= maxClass) {
              var timeTableObjectKey = weekDay + "-" + (previousClass + 1);
              var emptyEntry = {
                timetableId: timeTableObjectKey,
                groupId: null,
                period: previousClass + 1,
                startTime: "",
                endTime: "",
              };
              timeTables2.push(emptyEntry);
              previousClass = previousClass + 1;
            }
            return (
              <Stack
                direction={"column"}
                border={"1px solid"}
                borderColor="#E0E0E0"
                borderRadius="lg"
                w={"full"}
              >
                <Stack pl={2} pt={2}>
                  <Text
                    ml={2}
                    fontWeight="bold"
                    fontSize={{
                      base: "xs",
                      md: "sm",
                      lg: "md",
                      xl: "lg",
                    }}
                  >
                    {getDayToKorean(weekDay, t)}
                  </Text>
                </Stack>
                <VStack
                  borderTop={"1px solid"}
                  borderColor={"#E0E0E0"}
                  w="full"
                  h="full"
                  spacing={1}
                  bgColor={"#FAFAFA"}
                >
                  <Grid
                    w="full"
                    h="full"
                    px={4}
                    py={2}
                    gridTemplateRows={"repeat(8, 1fr)"}
                    gap={2}
                  >
                    {timeTables2.map((timeTable) => {
                      const targetGroup = allMyGroups.find(
                        (group) => group.groupId === timeTable.groupId
                      );
                      if (!targetGroup) {
                        if (timeTable.groupdId != null) return null;
                        var targetGroup_empty = {
                          grade: 0,
                          groupName: "empty_slot",
                          subjectName: "",
                        };
                        return (
                          <GridItem>
                            <CardLectureInfoEmpty
                              key={timeTable.timetableId}
                              timetable={timeTable}
                              group={targetGroup_empty}
                              modal_func={handleCreateModal}
                              prop_tables={timeTables}
                              prop_weekday={index + 1}
                            />
                          </GridItem>
                        );
                      }
                      return (
                        <GridItem>
                          <CardLectureInfo
                            key={timeTable.timetableId}
                            timetable={timeTable}
                            group={targetGroup}
                            modal_func={handleEditModal}
                            prop_tables={timeTables}
                            prop_weekday={index + 1}
                          />
                        </GridItem>
                      );
                    })}
                  </Grid>
                </VStack>
              </Stack>
            );
          }
        )}
      </SimpleGrid>
      {editModalShow && (
        <ModalEditTimetable
          show={editModalShow}
          onHide={() => setEditModalShow(false)}
          timetableData={formData}
          timetableList={timetableData}
        />
      )}
      {createModalShow && (
        <ModalCreateTimetable
          show={createModalShow}
          onHide={() => setCreateModalShow(false)}
          timetableData={formData}
          timetableList={timetableData}
        />
      )}
    </Container>
  );
};

export default ClassList;

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import ClassManagementWrapper from "./ClassManagementWrapper";
import { Divider } from "@mui/material";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ModalClassManagementAddApp from "../Modal/ModalClassManagementAddApp";
import { useGetPrograms } from "@app/hooks/queries/program/useProgram";
import { useTranslation } from "react-i18next";

const ClassManagementPannel = () => {
  const { groupId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  return (
    <>
      <Accordion allowMultiple>
        <AccordionItem>
          <Text>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                {t("timetable.focustime.accordion.titile.controllapp")}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Text>

          <AccordionPanel pb={4}>
            <Stack w="full" direction="column" spacing={2}>
              <HStack>
                <Button onClick={onOpen}>
                  {/* 앱/웹 제어 설정 추가 */}
                  {t("timetable.focustime.accordion.button.chooseapp")}
                </Button>
              </HStack>
              <Divider color="gray.200" />
              <ClassManagementWrapper />
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <ModalClassManagementAddApp
        groupId={groupId}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export default ClassManagementPannel;

import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  setScreenData,
  setSocketData,
  updateClassroomPromise,
} from "@app/store/actions";
import { socketMethod, socketUrl } from "@app/Constants/socketConstants";

export const useScreenShare = (user, activeClassroom, scale = 0.5) => {
  const [isSharing, setIsSharing] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [screenStream, setScreenStream] = useState(null);
  const [captureTimeout, setCaptureTimeout] = useState(null); // 캡처 타이머 ID를 저장하기 위한 상태
  const dispatch = useDispatch();

  // 비율을 유지하면서 화면 캡처
  // const capture = (video) => {
  //   const w = video.videoWidth;
  //   const h = video.videoHeight;
  //   const canvas = document.createElement("canvas");
  //   canvas.width = w * scale;
  //   canvas.height = h * scale;
  //   const ctx = canvas.getContext("2d");
  //   ctx.drawImage(video, 0, 0, w * scale, h * scale);
  //   return canvas;
  // };

  const capture = (video) => {
    const targetHeight = 720; // 720p 해상도에서의 높이
    const scaleFactor = targetHeight / video.videoHeight;
    const targetWidth = video.videoWidth * scaleFactor;

    let canvas = document.createElement("canvas");
    canvas.id = "canvas";
    canvas.width = targetWidth;
    canvas.height = targetHeight;
    let ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.drawImage(video, 0, 0, targetWidth, targetHeight);
    } else {
      console.error("2D 컨텍스트를 가져오지 못했습니다.");
    }

    return canvas;
  };

  // 화면 공유 시작
  const startSharing = useCallback(async () => {
    try {
      if (screenStream) {
        screenStream.getTracks().forEach((track) => track.stop());
      }
      if (captureTimeout) {
        clearTimeout(captureTimeout);
        setCaptureTimeout(null);
      }
      dispatch(
        updateClassroomPromise({
          classroomId: activeClassroom?.classroomId,
          screenShared: true,
        })
      );

      const newScreenStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });
      setScreenStream(newScreenStream);

      const video =
        document.getElementById("video") || document.createElement("video");
      video.id = "video";
      video.srcObject = newScreenStream;
      video.play().then(() => {
        const draw = () => {
          if (!video.paused && !video.ended) {
            const canvas = capture(video);
            const bigURI = canvas
              .toDataURL("image/webp", 0.5)
              .replace("data:image/webp;base64,", "");

            setCapturedImage(`data:image/webp;base64,${bigURI}`);

            dispatch(
              setScreenData({
                method: socketMethod.POST,
                uri: socketUrl.classroomSendImage,
                type: "IMAGE_TEACHER",
                clientId: user.clientId,
                groupId: activeClassroom?.groupId,
                data: bigURI,
              })
            );

            canvas.remove(); // 캔버스 메모리 해제

            const timeoutId = setTimeout(draw, 3000);
            setCaptureTimeout(timeoutId);
          }
        };
        draw();
      });

      setIsSharing(true);
    } catch (error) {
      console.error("화면 공유 시작에 실패했습니다.", error);
      setIsSharing(false);
    }
  }, [dispatch, user, activeClassroom, scale, screenStream, captureTimeout]);

  // 화면 공유 중지
  const stopSharing = useCallback(() => {
    const video = document.getElementById("video");
    if (video) {
      video.remove(); // 비디오 요소를 DOM에서 제거
    }
    if (video && video.srcObject) {
      const tracks = video.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      video.srcObject = null;
    }
    setCapturedImage(null);
    setIsSharing(false);
    dispatch(
      updateClassroomPromise({
        classroomId: activeClassroom?.classroomId,
        screenShared: false,
      })
    );

    if (screenStream) {
      screenStream.getTracks().forEach((track) => track.stop());
      setScreenStream(null); // 스트림 상태 초기화
    }
    if (captureTimeout) {
      clearTimeout(captureTimeout);
      setCaptureTimeout(null);
    }
  }, [screenStream, captureTimeout]);

  return { isSharing, startSharing, stopSharing, capturedImage };
};

import React, { useEffect } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { useLocation } from "react-router-dom";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

const InClassInformation = ({ group, data }) => {
  // console.log("group", group);
  const { t } = useTranslation();
  const location = useLocation();
  const { groupId } = useParams();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="cente r"
      width="100%"
      p={4}
      borderRadius="lg"
      border={"1px solid #ffffff"}
      bg={"#30599C"}
      color={"white"}
    >
      <Tooltip title={group?.subjectName} arrow>
        {group?.groupType === "AFTER" ? (
          <VStack
            w={"full"}
            h={"full"}
            spacing={0}
            alignItems="center"
            justifyContent="center"
          >
            <HStack>
              <Text
                fontSize={{
                  base: "sm",
                  md: "md",
                  lg: "xl",
                  xl: "2xl",
                }}
                fontWeight={"bold"}
              >
                {group?.grade} - {group?.groupName}
              </Text>
              {/* <Text
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                fontSize={{
                  base: "sm",
                  md: "md",
                  lg: "xl",
                  xl: "2xl",
                }}
                fontWeight={"bold"}
              >
                &nbsp;{group?.subjectName}
              </Text> */}
            </HStack>
          </VStack>
        ) : (
          <VStack
            w={"full"}
            h={"full"}
            spacing={0}
            alignItems="center"
            justifyContent="center"
          >
            <HStack>
              <Text
                fontSize={{
                  base: "sm",
                  md: "md",
                  lg: "xl",
                  xl: "2xl",
                }}
                fontWeight={"bold"}
              >
                {group?.grade} - {group?.groupName}
              </Text>
              <Text
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                fontSize={{
                  base: "sm",
                  md: "md",
                  lg: "xl",
                  xl: "2xl",
                }}
                fontWeight={"bold"}
              >
                &nbsp;{group?.subjectName}
              </Text>
            </HStack>
          </VStack>
        )}
      </Tooltip>
    </Box>
  );
};

export default InClassInformation;

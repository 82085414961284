import React from "react";
import { profileImgPath } from "@api";
import { purgeAll, setReportLog } from "@app/store/actions";
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Img,
  Button,
  Stack,
  Divider,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { FiHome, FiTrendingUp, FiCompass, FiMenu } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import focuspangLogo from "assets/images/logo/focuspang_ai.png";
import { ButtonConstants } from "@app/Constants/ButtonConstants";

import MenuBookIcon from "@mui/icons-material/MenuBook";
import ForumIcon from "@mui/icons-material/Forum";
import { useTranslation } from "react-i18next";
import MultiLanguageSelectMenu from "@app/components/Buttons/MultiLanguage/MultiLanguageSelectMenu";
import { Tooltip } from "@mui/material";
import { LogoutOutlined, Settings } from "@mui/icons-material";

const SidebarContent = ({ onClose, ...rest }) => {
  const user = useSelector((state) => state.user);
  const history = useHistory();

  const handleSettingClick = () => {
    history.push("/settings");
  };

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const LinkItems = [
    // 처음으로
    { name: t("sidemenu.menu.home"), icon: FiHome, link: "/home" },
    // 수업 외 앱/웹 제어
    {
      name: t("sidemenu.menu.webapplimit"),
      icon: FiTrendingUp,
      link: "/managing",
    },
    // 퀴즈팡 관리
    {
      name: t("sidemenu.menu.quizpang"),
      icon: FiCompass,
      link: "/quiz-management",
    },
  ];

  const myProfileImage = useMemo(() => {
    if (!user) {
      return undefined;
    }
    return profileImgPath(user.clientId);
  }, [user]);

  const onPurgeClick = () => {
    // 로그아웃 하시겠어요?
    if (window.confirm(t("sidemenu.menu.logout.alert.text"))) {
      dispatch(purgeAll());
      history.replace("/auth");
    }
  };

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("whtie", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60, lg: 72, xl: 80 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Stack direction={"column"} spacing={1} w={"full"} p={4} mt={6}>
        <HStack spacing={4}>
          <Avatar
            size={{
              base: "sm",
              md: "sm",
              lg: "md",
            }}
            name={user?.userName}
            src={myProfileImage}
          />
          <Stack spacing={1} direction={"column"} w={"full"}>
            <HStack justifyContent={"space-between"} w={"full"}>
              <Text
                fontSize={{
                  base: "sm",
                  md: "sm",
                  lg: "md",
                  xl: "lg",
                }}
                fontWeight="bold"
              >
                {user?.userName}
              </Text>
              <IconButton
                variant="ghost"
                colorScheme="gray"
                color="gray.300"
                icon={<Settings />}
                onClick={handleSettingClick}
                size="sm"
              />
            </HStack>
            <Text
              fontSize={"sm"}
              display={{
                base: "none",
                lg: "block",
              }}
            >
              {user?.email}
            </Text>
          </Stack>
          <CloseButton
            display={{ base: "flex", md: "none" }}
            onClick={onClose}
          />
        </HStack>
        <Divider color={"gray.200"} mt={2} />
      </Stack>
      {LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} link={link.link}>
          {link.name}
        </NavItem>
      ))}
      <Box
        position="absolute"
        bottom="10"
        width="100%"
        display="flex"
        justifyContent="flex-start"
        p={4}
      >
        <VStack spacing="2" w={"full"} align="flex-start">
          <Button
            w="full"
            leftIcon={<ForumIcon />}
            onClick={() => {
              dispatch(
                setReportLog(ButtonConstants.CONFIG.LINK_TO_FOCUSPANG_ASK)
              );
            }}
          >
            <a href="https://focuspang.com/ask.html" target="_black">
              {/* 포커스팡 즉문즉답 */}
              {t("sidemenu.setting.button.qna")}
            </a>
          </Button>
          <Button
            w="full"
            leftIcon={<MenuBookIcon />}
            onClick={() => {
              dispatch(
                setReportLog(ButtonConstants.CONFIG.LINK_TO_FOCUSPANG_GUIDE)
              );
            }}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            mb={8}
          >
            <a href="https://focuspang.com/guide_2.html" target="_black">
              {/* 포커스팡 매뉴얼 */}
              {t("sidemenu.setting.button.manual")}
            </a>
          </Button>
        </VStack>
      </Box>
    </Box>
  );
};

const NavItem = ({ icon, children, link, ...rest }) => {
  const history = useHistory();
  const location = useLocation();
  const isActive = location.pathname.slice(0, link.length) === link;
  const isCurrentPage = location.pathname === link;

  return (
    <Box
      as="a"
      onClick={() => {
        if (!isCurrentPage) {
          history.push(link);
        }
      }}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        mt="2"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        bg={isActive ? "#447EDC" : "transparent"} // 활성화 상태에 따라 배경색 변경
        color={isActive ? "white" : "#888888"} // 활성화 상태에 따라 텍스트 색상 변경
        _hover={{
          bg: "#3B6DBF",
          color: "white",
        }}
        {...rest}
        fontWeight={"bold"}
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
            color={isActive ? "white" : "#888888"}
          />
        )}
        {children}
      </Flex>
    </Box>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
        <Img src={focuspangLogo} h={{ base: "20px", md: "30px" }} />
      </Text>
    </Flex>
  );
};

const SidebarWithHeader = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60, lg: 72, xl: 80 }} pl={8} pr={8} minH="100vh">
        {children}
      </Box>
    </Box>
  );
};

export default SidebarWithHeader;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";

const pc_config = {
  iceServers: [
    {
      urls: "stun:stun.l.google.com:19302",
    },
    {
      urls: "turn:211.184.226.180:3478?transport=udp",
      username: "rubis",
      credential: "4542rubis!",
    },
  ],
  iceTransportPolicy: "all",
};

const SOCKET_SERVER_URL = "https://relay.focuspang.ai";

const useRTC = () => {
  const user = useSelector((state) => state.user);
  const [sourceType, setSourceType] = useState("screen");
  const [isSharing, setIsSharing] = useState(false);
  const socketRef = useRef(null);
  const pcsRef = useRef({});
  const localVideoRef = useRef(null);
  const localStreamRef = useRef();
  const [users, setUsers] = useState([]);
  const userRef = useRef(users);
  const myUUID = uuidv4();
  const [networkSpeedType, setNetworkSpeedType] = useState("");
  const [steamUserChange, setSteamUserChange] = useState("");

  const onUserClick = (userId) => {
    const selectedUser = users.find((user) => user.id === userId);
    if (selectedUser) {
      // 당신의 현재 로컬 스트림을 선택한 사용자의 스트림으로 바꿉니다.
      localStreamRef.current = selectedUser.stream;

      // 로컬 비디오 엘리먼트를 업데이트합니다.
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = selectedUser.stream;
      }

      // 당신이 연결된 모든 피어에게 새로운 스트림을 전송합니다.
      Object.values(pcsRef.current).forEach((pc) => {
        if (!localStreamRef.current) return;
        const senders = pc.getSenders();

        localStreamRef.current.getTracks().forEach((track, index) => {
          if (senders[index]) {
            senders[index].replaceTrack(track);
          }
        });
      });

      // 선택한 사용자의 화면을 자신의 화면에 표시하도록 서버에 알림.
      if (socketRef.current) {
        socketRef.current.emit("switch_stream", {
          streamOwner: userId,
          newStream: selectedUser.stream,
        });
      }
    }
  };
  const capture = (video) => {
    // 캡쳐한 이미지의 원본 비율을 유지하면서 캡쳐
    var w = video.videoWidth;
    var h = video.videoHeight;
    var canvas = document.createElement("canvas");
    canvas.id = "canvas";

    // Calculate scaleFactor to maintain aspect ratio
    var shorterSide = Math.min(w, h);
    // var base = shorterSide / 2; // Change this to modify scaling
    var base = shorterSide;
    var scaleFactor = 1 / (shorterSide / base);

    canvas.width = w * scaleFactor;
    canvas.height = h * scaleFactor;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, w * scaleFactor, h * scaleFactor);

    return canvas;
  };

  // const captureAndSendImage = () => {
  //   if (localVideoRef.current) {
  //     const canvas = capture(localVideoRef.current);
  //     const img = canvas
  //       .toDataURL('image/webp', 0.5)
  //       .replace('data:image/webp;base64,', '');

  //     if (img.length >= 22) {
  //       socketRef.current.emit('send_captured_image', {
  //         clientId: user.clientId,
  //         image: img,
  //       });
  //     }
  //   }
  // };

  const getLocalStream = useCallback(async () => {
    try {
      let localStream;
      if (sourceType === "webcam") {
        localStream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            width: 240,
            height: 240,
          },
        });
      } else if (sourceType === "screen") {
        localStream = await navigator.mediaDevices.getDisplayMedia({
          audio: false,
          video: {
            width: 240,
            height: 240,
          },
        });

        localStream
          .getVideoTracks()[0]
          .applyConstraints({ frameRate: { max: 5 } });

        // 네트워크 속도 유형에 따른 프레임 제한
        // if (connectionType === '2g' || connectionType === '3g') {
        //   localStream
        //     .getVideoTracks()[0]
        //     .applyConstraints({ frameRate: { max: 25 } });
        // } else if (connectionType === '4g' || connectionType === '5g') {
        //   localStream
        //     .getVideoTracks()[0]
        //     .applyConstraints({ frameRate: { max: 25 } });
        // } else {
        //   localStream
        //     .getVideoTracks()[0]
        //     .applyConstraints({ frameRate: { max: 25 } });
        // }
      }

      localStreamRef.current = localStream;
      if (localVideoRef.current) localVideoRef.current.srcObject = localStream;
      if (!socketRef.current) return;
      socketRef.current.emit("join_room", {
        room: "1234",
        clientId: user.clientId,
        // email: 'teacher@naver.com',
      });
    } catch (e) {
      console.error(`getUserMedia error: ${e}`);
    }
  }, [sourceType]);

  const stopLocalStream = useCallback(() => {
    if (localStreamRef.current) {
      localStreamRef.current.getTracks().forEach((track) => track.stop());
      localStreamRef.current = null;
    }
  }, []);

  const createPeerConnection = useCallback((socketID, clientId) => {
    try {
      const pc = new RTCPeerConnection(pc_config);

      pc.onicecandidate = (e) => {
        if (!(socketRef.current && e.candidate)) return;
        // console.log('onicecandidate', e);
        socketRef.current.emit("candidate", {
          candidate: e.candidate,
          candidateSendID: socketRef.current.id,
          candidateReceiveID: socketID,
        });
      };

      pc.oniceconnectionstatechange = (e) => {
        // console.log('oniceconnectionstatechange', e);
      };

      pc.ontrack = (e) => {
        // console.log(
        //   'ontrack success',
        //   users,
        //   userRef.current,
        //   socketID,
        //   email,
        //   e.streams[0]
        // );
        setUsers((oldUsers) => {
          return oldUsers
            .filter((user) => user?.id !== socketID)
            .concat({
              id: socketID,
              clientId,
              stream: e.streams[0],
            });
        });

        // console.log('setusers after', users);
      };

      if (localStreamRef.current) {
        // console.log('localstream add');
        localStreamRef.current.getTracks().forEach((track) => {
          if (!localStreamRef.current) return;
          pc.addTrack(track, localStreamRef.current);
        });
      } else {
        console.log("no local stream");
      }

      return pc;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }, []);

  useEffect(() => {
    if (isSharing) {
      getLocalStream();
    } else {
      stopLocalStream();
    }

    socketRef.current = io.connect(SOCKET_SERVER_URL, {
      cors: { origin: "*" },
    });

    socketRef.current.on("display_stream", (userId) => {
      const selectedUser = users.find((user) => user.id === userId);
      if (selectedUser && localVideoRef.current) {
        localVideoRef.current.srcObject = selectedUser.stream;
      }
    });

    socketRef.current.on("all_users", (allUsers) => {
      allUsers.forEach(async (user) => {
        if (!localStreamRef.current) return;
        const pc = createPeerConnection(user.id, user.email);
        if (!(pc && socketRef.current)) return;
        pcsRef.current = { ...pcsRef.current, [user.id]: pc };
        try {
          const localSdp = await pc.createOffer({
            offerToReceiveAudio: true,
            offerToReceiveVideo: true,
          });
          console.log("create offer success");
          await pc.setLocalDescription(new RTCSessionDescription(localSdp));
          socketRef.current.emit("offer", {
            sdp: localSdp,
            offerSendID: socketRef.current.id,
            offerSendEmail: `${user.id}@sample.com`,
            offerReceiveID: user.id,
          });
        } catch (e) {
          console.error(e);
        }
      });
    });

    socketRef.current.on("getOffer", async (data) => {
      const { sdp, offerSendID, offerSendEmail } = data;
      console.log("get offer");
      if (!localStreamRef.current) return;
      const pc = createPeerConnection(offerSendID, offerSendEmail);
      if (!(pc && socketRef.current)) return;
      pcsRef.current = { ...pcsRef.current, [offerSendID]: pc };
      try {
        await pc.setRemoteDescription(new RTCSessionDescription(sdp));
        console.log("answer set remote description success");
        const localSdp = await pc.createAnswer({
          offerToReceiveVideo: true,
          offerToReceiveAudio: true,
        });
        await pc.setLocalDescription(new RTCSessionDescription(localSdp));
        socketRef.current.emit("answer", {
          sdp: localSdp,
          answerSendID: socketRef.current.id,
          answerReceiveID: offerSendID,
        });
      } catch (e) {
        console.error(e);
      }
    });

    socketRef.current.on("getAnswer", (data) => {
      const { sdp, answerSendID } = data;
      //   console.log('get answer');
      const pc = pcsRef.current[answerSendID];
      if (!pc) return;
      pc.setRemoteDescription(new RTCSessionDescription(sdp));
    });

    socketRef.current.on("getCandidate", async (data) => {
      //   console.log('get candidate');
      const pc = pcsRef.current[data.candidateSendID];
      if (!pc) return;
      await pc.addIceCandidate(new RTCIceCandidate(data.candidate));
      //   console.log('candidate add success');
    });

    socketRef.current.on("user_exit", (data) => {
      if (!pcsRef.current[data.id]) return;
      pcsRef.current[data.id].close();
      delete pcsRef.current[data.id];
      setUsers((oldUsers) => oldUsers.filter((user) => user.id !== data.id));
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
      users.forEach((user) => {
        if (!pcsRef.current[user.id]) return;
        pcsRef.current[user.id].close();
        delete pcsRef.current[user.id];
      });
      if (!isSharing) {
        stopLocalStream();
      }
    };
  }, [createPeerConnection, getLocalStream, isSharing, stopLocalStream]);

  return {
    localVideoRef,
    users,
    setIsSharing,
    onUserClick,
  };
};
export default useRTC;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import styles from "./ModalEditClassTimetable.module.scss";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { selectAllMyGroups } from "@store/selectors";
import {
  createTimetablePromise,
  deleteTimetablePromise,
  updateTimetablePromise,
  createGroupPromise,
  updateGroupPromise,
  createMembershipPromise,
} from "@store/actions";
import BaseSelect from "../Select/baseSelect";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { isArray } from "@craco/craco/lib/utils";
import { deleteGroupPromise, setSocketData } from "@app/store/actions";
import {
  classGrouplist,
  classtimes,
  gradeList,
  weekdays,
} from "@app/Constants/groupTimetable";
import classNames from "classnames";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export const Dropdown = ({
  title = "",
  name,
  value,
  onChange = () => {},
  onClick = () => {},
  data = [],
  defaultValue,
}) => {
  const { t } = useTranslation();

  return (
    <BaseSelect
      title={title}
      name={name}
      onChange={onChange}
      onClick={onClick}
      value={value}
      defaultValue={defaultValue}
    >
      <option style={{ display: "none" }}>{title}</option>
      {data.map((d, idx) => {
        return (
          <option value={d[0]} id={idx} key={idx}>
            {t(d[1])}
          </option>
        );
      })}
    </BaseSelect>
  );
};

const initTimetableData = {
  week: "",
  period: "",
  startTime: "09:00",
  endTime: "09:45",
};
const initGradeData = {
  timetable: [{ ...initTimetableData }],
  groupType: "SUBJECT",
  groupName: "",
  grade: "",
};
const ModalEditClassTimetable = ({ formData, show, onHide }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [subjectName, setSubjectName] = useState(formData.subjectName || "");
  const [creatingFields, setCreatingFields] = useState([
    {
      ...initGradeData,
      timetable: [
        {
          ...initTimetableData,
          week: formData.weekDay,
          period: formData.period,
        },
      ],
    },
  ]);
  const [editingFields, setEditingFields] = useState({
    groupType: formData.groupType || "SUBJECT",
    groupName: formData.groupName,
    grade: formData.grade,
    week: formData.weekDay,
    period: formData.period,
    startTime: formData.startTime,
    endTime: formData.endTime,
  });

  const { clientId, schoolId } = useSelector((state) => state.user);
  const allMyGroups = useSelector((state) => selectAllMyGroups(state));
  const [loading, setLoading] = useState(false);
  const [startCheck, setStartCheck] = useState("09:00");
  const [endCheck, setEndCheck] = useState("09:45");
  const startH = startCheck.substring(0, 2) * 60;
  const startM = startCheck.substring(3, 5);
  const startSum = parseInt(startH) + parseInt(startM);
  const endH = endCheck.substring(0, 2) * 60;
  const endM = endCheck.substring(3, 5);
  const endSum = parseInt(endH) + parseInt(endM);
  // const [formCheck, setFormCheck] = useState(false);
  const [timeCheck, setTimeCheck] = useState(false);
  const [timeCheckArray, setTimeCheckArray] = useState([]);
  const [editingTimeCheck, setEditingTimeCheck] = useState(false);

  const findGroupId = async (grade, groupName, subjectName) => {
    const group = allMyGroups.find(
      (group) =>
        group.groupName.toString() === groupName &&
        group.grade.toString() === grade &&
        group.subjectName === subjectName
    );
    return group?.groupId;
  };

  useEffect(() => {
    creatingFields.map((field) => {
      field.timetable.map((time) => {
        const key = `${time.week}${time.period}`;
        if (time.endTime > time.startTime) {
          setTimeCheckArray((prev) => ({ ...prev, [key]: false }));
        }
        if (time.endTime <= time.startTime) {
          setTimeCheckArray((prev) => ({ ...prev, [key]: true }));
        }
      });
    });
  }, [creatingFields]);

  useEffect(() => {
    if (editingFields.endTime > editingFields.startTime) {
      setTimeCheck(false);
    }
    if (editingFields.endTime <= editingFields.startTime) {
      setTimeCheck(true);
    }
  }, [editingFields]);

  useEffect(() => {
    const check = Object.values(timeCheckArray).some((value) => value);
    setTimeCheck(check);
  }, [timeCheckArray]);

  const sendEditTimetableBySocket = useCallback((groupId) => {
    dispatch(
      setSocketData({
        method: "POST",
        uri: "/classroom/sendImage",
        groupId: groupId,
        clientId: user?.clientId,
        type: "EDIT_TIMETABLE",
        data: "",
      })
    );
  });

  const onAddGroup = () => {
    setCreatingFields([
      ...creatingFields,
      {
        ...initGradeData,
        timetable: [{ ...initTimetableData }],
      },
    ]);
  };
  const onAddTimetable = (fieldIndex) => {
    let _createFields = [...creatingFields];
    _createFields[fieldIndex].timetable.push({ ...initTimetableData });
    setCreatingFields(_createFields);
  };
  const onRemoveTimetable = (fieldIndex, timeIndex) => {
    let _createFields = [...creatingFields];
    _createFields[fieldIndex].timetable.splice(timeIndex, 1);
    if (!_createFields[fieldIndex].timetable.length) {
      _createFields.splice(fieldIndex, 1);
    }
    setCreatingFields(_createFields);
  };
  const onChangeGroup = (fieldIndex, e) => {
    const { name, value } = e.target;
    let _createFields = [...creatingFields];
    _createFields[fieldIndex][name] = value;
    setCreatingFields(_createFields);
  };
  const onChangeTimetable = (fieldIndex, timeIndex, e) => {
    const { name, value } = e.target;
    let _createFields = [...creatingFields];
    _createFields[fieldIndex].timetable[timeIndex][name] = value;
    setCreatingFields(_createFields);
  };
  const onChangeEditData = (e) => {
    const { name, value } = e.target;
    setEditingFields((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const constants = useMemo(() => {
    const grades = [...new Set(allMyGroups.map((group) => group.grade))];
    const myClassLists = [
      ...new Set(
        allMyGroups.map(
          (group) =>
            `${group.grade}학년 ${group.groupName}반 ${group.subjectName}수업`
        )
      ),
    ];
    grades.sort((a, b) => a - b);
    myClassLists.sort((a, b) => a - b);
    const _grades = grades.map((g) => [g, g.toString()]);
    const _myClassLists = myClassLists.map((g) => [g, g.toString()]);

    return {
      grades: _grades,
      myClassLists: _myClassLists,
      classGradelist: gradeList,
      classtimes: classtimes,
      weekdays: weekdays,
      classGrouplist: classGrouplist,
    };
  }, [allMyGroups]);

  const creatingTimetableDisabled = useMemo(
    () =>
      !!creatingFields.filter((field) => {
        if (!subjectName) {
          return true;
        }
        // if (formCheck) {
        //   return true;
        // }
        if (timeCheck) {
          return true;
        }
        const values = Object.values(field);
        return values.reduce((prev, curr) => {
          if (prev) return true;
          if (isArray(curr)) {
            return !!curr.filter((item) =>
              Object.values(item).some(
                (value) => value === undefined || value === ""
              )
            ).length;
          }
          return values.some((value) => value === undefined || value === "");
        }, false);
      }).length,
    [creatingFields, subjectName, timeCheck]
  );
  const editingTimetableDisabled = useMemo(() => {
    if (!subjectName) {
      return true;
    }
    if (timeCheck) {
      return true;
    }
    const values = Object.values(editingFields);
    return values.some((value) => value === undefined || value === "");
  }, [editingFields, subjectName, timeCheck]);

  const onCreate = async () => {
    setLoading(true);
    for (let i = 0; i < creatingFields.length; i++) {
      console.log("field", creatingFields[i]);
      const field = creatingFields[i];
      const groupData = {
        schoolId: schoolId,
        state: "CREATED",
        groupType: field.groupType,
        subjectName,
        groupName: field.groupName,
        grade: field.grade,
      };
      // 중복된 학년과 반 그리고 수업 명이 있는지 확인하는 로직
      const duplicateGroup = await findGroupId(
        field.grade,
        field.groupName,
        subjectName
      );
      // 중복된 학년과 반 그리고 수업 명이 없다면 새로운 그룹을 생성한 뒤 그룹 데이터를 가지고 멤버쉽을 생성한다 그 다음 시간표를 생성한다.
      if (!duplicateGroup) {
        const createdGroup = await dispatch(createGroupPromise(groupData));
        dispatch(
          createMembershipPromise({
            groupId: createdGroup.groupId,
            clientId: user.clientId,
            role: "TEACHER",
          })
        );

        await Promise.all(
          field.timetable.map(async (time) => {
            const timetableData = {
              groupId: createdGroup.groupId,
              weekday: time.week,
              period: time.period,
              startTime: time.startTime,
              endTime: time.endTime,
            };
            await dispatch(createTimetablePromise(timetableData));
            return true;
          })
        );
      } else {
        await Promise.all(
          field.timetable.map(async (time) => {
            const timetableData = {
              groupId: duplicateGroup,
              weekday: time.week,
              period: time.period,
              startTime: time.startTime,
              endTime: time.endTime,
            };
            await dispatch(createTimetablePromise(timetableData));
            return true;
          })
        );
      }
    }
    setLoading(false);
    onHide();
  };

  const editTimetable = () => {
    if (window.confirm("수업 정보를 수정하시겠습니까?")) {
      setLoading(true);
      const updatedGroupData = {
        groupId: formData.groupId,
        groupType: editingFields.groupType,
        subjectName,
        groupName: editingFields.groupName,
        grade: editingFields.grade,
        // clientId,
      };
      const updatedTimetableData = {
        timetableId: formData.timetableId,
        weekday: editingFields.week,
        period: editingFields.period,
        startTime: editingFields.startTime,
        endTime: editingFields.endTime,
      };
      dispatch(updateGroupPromise(updatedGroupData)).then((res) => {
        dispatch(updateTimetablePromise(updatedTimetableData)).then(() => {
          setLoading(false);
          onHide();
        });
        sendEditTimetableBySocket(res.groupId);
      });
    }
  };
  const onDeleteTimetable = async () => {
    setLoading(true);
    if (window.confirm("정말 삭제하시겠습니까?")) {
      await dispatch(deleteTimetablePromise(formData.timetableId));

      if (window.confirm("선택하신 수업을 삭제하시겠습니까?")) {
        if (formData.timetables.length === 1) {
          dispatch(deleteGroupPromise(formData.groupId)).then(() => {
            dispatch(
              setSocketData({
                method: "POST",
                uri: "/classroom/sendImage",
                groupId: formData.groupId,
                clientId: user.clientId,
                type: "CLASS_DELETE",
                data: "",
              })
            ).catch((err) => {
              console.error(err);
            });
          });
        }
      }
      setLoading(false);
      onHide();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      className={styles["AddApp-container"]}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header>
        <div className={styles["title-css"]}>
          {formData.isEdit ? "수업 정보를 변경합니다." : `수업을 추가합니다.`}
        </div>
        {formData.isEdit ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              // 오른쪽 끝에 배치
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={onDeleteTimetable}
              variant="outlined"
              startIcon={<DeleteIcon />}
            >
              수업삭제
            </Button>
          </div>
        ) : null}
      </Modal.Header>
      <Modal.Body>
        {formData.isEdit ? (
          <Grid container spacing={0}>
            <Grid xs={12} md={12}>
              <Box
                sx={{
                  margin: 1,
                }}
              >
                <Typography variant="body1">과목명</Typography>
                <TextField
                  id="subjectName"
                  name="subjectName"
                  variant="outlined"
                  onChange={(e) => setSubjectName(e.target.value)}
                  value={subjectName}
                  sx={{ width: "40%" }}
                  inputProps={{
                    maxLength: 25,
                  }}
                />
              </Box>
            </Grid>
            <Grid xs={4} md={4}>
              <FormControl variant="outlined" sx={{ m: 1, minWidth: "40%" }}>
                <Typography variant="body1">학년</Typography>
                <Select
                  value={editingFields.grade}
                  onChange={onChangeEditData}
                  name="grade"
                >
                  {constants.classGradelist.map((grade, idx) => (
                    <MenuItem key={idx} value={grade[0]}>
                      {grade[1]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" sx={{ m: 1, minWidth: "40%" }}>
                <Typography variant="body1">반</Typography>
                <Select
                  value={editingFields.groupName}
                  onChange={onChangeEditData}
                  name="groupName"
                >
                  {constants.classGrouplist.map((group, idx) => (
                    <MenuItem key={idx} value={group[0]}>
                      {group[1]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={4} md={4}>
              <FormControl
                variant="outlined"
                sx={{ m: 1, ml: 4, minWidth: "40%" }}
              >
                <Typography variant="body1">요일</Typography>
                <Select
                  value={editingFields.week}
                  onChange={onChangeEditData}
                  name="week"
                >
                  {constants.weekdays.map((weekday, idx) => (
                    <MenuItem key={idx} value={weekday[0]}>
                      {weekday[1]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined" sx={{ m: 1, minWidth: "40%" }}>
                <Typography variant="body1">교시</Typography>
                <Select
                  value={editingFields.period}
                  onChange={onChangeEditData}
                  name="period"
                >
                  {constants.classtimes.map((classtime, idx) => (
                    <MenuItem key={idx} value={classtime[0]}>
                      {classtime[1]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={4} md={4}>
              <Typography variant="body1" sx={{ ml: 1 }}>
                수업 시작/종료 시간
              </Typography>
              <TextField
                id="startTime"
                name="startTime"
                type="time"
                value={editingFields.startTime}
                onChange={onChangeEditData}
                sx={{ m: 1, minWidth: "40%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="endTime"
                name="endTime"
                type="time"
                value={editingFields.endTime}
                onChange={onChangeEditData}
                sx={{ m: 1, minWidth: "40%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <>
            <div
              className={styles["body-main"]}
              style={{
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <div className={styles["body-right"]}>
                <div className={styles["modal-body"]}>
                  <div className={styles["modal-body-subject"]}>
                    <div className={styles["body-title"]}>과목명</div>
                    <TextField
                      variant="outlined"
                      id="subjectName"
                      name="subjectName"
                      placeholder="과목을 입력 해주세요"
                      onChange={(e) => setSubjectName(e.target.value)}
                      value={subjectName}
                    />
                  </div>
                </div>
              </div>
            </div>
            {creatingFields.map((createData, fieldIndex) => (
              <div key={fieldIndex} className={styles["body-main"]}>
                <div className={styles["body-right"]}>
                  <div className={styles["modal-body"]}>
                    <div className={styles["modal-body-grade"]}>
                      <div className={styles["body-title"]}>학년</div>
                      <Dropdown
                        title="학년"
                        name="grade"
                        value={createData.grade}
                        onChange={(value) => onChangeGroup(fieldIndex, value)}
                        data={constants.classGradelist}
                      />
                    </div>
                    <div className={styles["modal-body-group"]}>
                      <div className={styles["body-title"]}>반</div>
                      <Dropdown
                        className={styles["group-input"]}
                        id="groupName"
                        name="groupName"
                        // placeholder="반"
                        title="반"
                        onChange={(value) => onChangeGroup(fieldIndex, value)}
                        value={createData.groupName}
                        data={constants.classGrouplist}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles["left-container-period"]}>
                  {createData.timetable.map((timeData, timeIndex) => (
                    <div key={timeIndex} className={styles["body-left"]}>
                      <div className={styles["modal-body-subjectTime"]}>
                        <div className={styles["body-title"]}>요일/교시</div>
                        <div className={styles["modal-body-daySetting"]}>
                          <div className={styles["modal-body-week"]}>
                            <Dropdown
                              title="요일"
                              name="week"
                              value={timeData.week}
                              onChange={(value) =>
                                onChangeTimetable(fieldIndex, timeIndex, value)
                              }
                              data={weekdays}
                            />
                          </div>
                          <div className={styles["modal-body-period"]}>
                            <Dropdown
                              title="교시"
                              name="period"
                              value={timeData.period}
                              onChange={(value) =>
                                onChangeTimetable(fieldIndex, timeIndex, value)
                              }
                              data={classtimes}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles["modal-body-left"]}>
                        <div className={styles["modal-body-subjectTime"]}>
                          <Tooltip
                            title="수업 시간의 종료 시간이 시작 시간보다 빠르거나 같지 않게 선택해 주세요."
                            arrow
                            placement="top"
                          >
                            <div
                              className={styles["body-title"]}
                              style={{ width: 100 }}
                            >
                              수업 시간
                            </div>
                          </Tooltip>

                          <div className={styles["modal-body-timeSetting"]}>
                            <input
                              className={styles["start-time"]}
                              type="time"
                              id="start-time"
                              name="startTime"
                              placeholder="수업 시작 시간"
                              onChange={(value) => {
                                onChangeTimetable(fieldIndex, timeIndex, value);
                                setStartCheck(value.target.value);
                              }}
                              onFocus={(e) => (e.target.type = "time")}
                              value={timeData.startTime}
                            />
                            <div className={styles["modal-text"]}>-</div>
                            <div className={styles["modal-body-end"]}>
                              <input
                                className={styles["end-time"]}
                                type="time"
                                id="end-time"
                                name="endTime"
                                placeholder="수업 종료 시간"
                                onFocus={(e) => (e.target.type = "time")}
                                onChange={(value) => {
                                  setEndCheck(value.target.value);
                                  onChangeTimetable(
                                    fieldIndex,
                                    timeIndex,
                                    value
                                  );
                                }}
                                value={timeData.endTime}
                              />
                            </div>
                            <RemoveCircleOutlineIcon
                              type="button"
                              className={styles["delete-modal"]}
                              onClick={() =>
                                onRemoveTimetable(fieldIndex, timeIndex)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div
                    className={styles["body-bottom"]}
                    style={{ marginTop: 6 }}
                  >
                    {timeCheck ? (
                      <div className={styles["class-time-check"]}>
                        ⦁ 수업 종료 시간을 올바르게 설정해 주세요.
                      </div>
                    ) : null}
                  </div>
                  <div className={styles["body-bottom"]}>
                    <button onClick={() => onAddTimetable(fieldIndex)}>
                      <AddIcon
                        className={classNames(
                          styles["add-icon"],
                          styles["blinking"]
                        )}
                      />
                      <div className={styles["modal-add"]}>
                        수업 시간 추가하기
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </Modal.Body>
      {!formData.isEdit && (
        <div className={styles["modal-period-add"]}>
          <div
            className={styles["modal-period-info"]}
            type="button"
            onClick={onAddGroup}
          >
            <AddCircleIcon
              className={classNames(
                styles["modal-period-Icon"]
                // , styles['blinking']
              )}
            />
            <div className={styles["modal-period-text"]}>
              수업할 반을 추가하기
            </div>
          </div>
        </div>
      )}
      <div className={styles["modal-bg-footer"]}>
        <button
          className={styles["footer-button-cancel"]}
          type="button"
          onClick={onHide}
        >
          취소
        </button>
        {formData.isEdit ? (
          <Button
            variant="contained"
            onClick={editTimetable}
            disabled={editingTimetableDisabled}
          >
            변경
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              onCreate();
            }}
            disabled={creatingTimetableDisabled}
          >
            추가
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default ModalEditClassTimetable;

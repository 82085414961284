import React, { useEffect, useState } from "react";
import ManagingControl from "@app/components/Layout/ManagingControl";
import ClassroomWrapper from "@app/components/Layout/Common/ClassroomWrapper";
import { dispatch } from "@adobe/redux-saga-promise";
import { purgeReportList, setSelectedPage } from "@app/store/actions";
import ModalAddManage from "@app/components/Modal/ModalAddManage";
import { Button } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { selectManagingGroup } from "@app/store/selectors";
import { useTranslation } from "react-i18next";

const ManagingWrapper = () => {
  const [modalShow, setModalShow] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [managingGroupForUpdate, setManagingGroupForUpdate] = useState();
  const groupId = useSelector((state) => state.control.selectedGroup);

  const managingClassList = useSelector((state) => selectManagingGroup(state));

  const managingGroup = managingClassList?.find(
    (managingClass) => managingClass.groupId === groupId
  );

  useEffect(() => {
    dispatch(setSelectedPage("MANAGINGLIST"));
    dispatch(purgeReportList());
  }, []);
  const { t } = useTranslation();
  return (
    <ClassroomWrapper
      // currentPageName={"수업 시간 외 앱/웹 사용 관리"}
      currentPageName={t("sidemenu.menu.webapplimit")}
      buttons={[
        <Button
          onClick={() => {
            setIsUpdateModal(true);
            setModalShow(true);
            setManagingGroupForUpdate(managingGroup);
          }}
        >
          {/* 관리 그룹 수정 */}
          {t("webapplimit.button.editgroup")}
        </Button>,
      ]}
    >
      <ManagingControl key={groupId} />;
      <ModalAddManage
        isUpdateModal={isUpdateModal}
        managingGroupForUpdate={managingGroupForUpdate}
        setManagingGroupForUpdate={setManagingGroupForUpdate}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </ClassroomWrapper>
  );
};

export default ManagingWrapper;

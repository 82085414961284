import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "@components/Layout/Sidebar";
import ClassList from "@components/Layout/ClassList";
import EventPanel from "@components/Layout/EventPanel";
import { setToken, getStorageData, useInterval } from "@api";
import { purgeAll } from "@app/store/actions";
import { useHistory } from "react-router-dom";
import winterIsComming from "assets/images/winterIsComming.png";
import { NotiPanel } from "@app/components/Layout/NotiPanel";
import { VerticalSpace8 } from "../QuizPang/common";
import Header from "@app/components/Layout/Header";
import { Box } from "@mui/material";
import Body from "@app/components/Layout/Body";
import SidebarWithHeader from "@app/components/Layout/Common/Sidebar";
import Wrapper from "@app/components/Layout/Common/Wrapper";
import { Button } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

const Home = () => {
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user.token !== undefined && user.token !== null) {
      setToken(user.token).then(() => {});
    }
  }, []);
  const { t } = useTranslation();
  return (
    <Wrapper
      // buttons={[<Button startIcon={<AddIcon />}>수업 추가</Button>]}
      currentPageName={t("timetable.text.title")}
      // currentPageName={시간표}}
    >
      <ClassList />
    </Wrapper>
  );
};

export default Home;

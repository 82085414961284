import React from "react";
import { useHistory } from "react-router-dom";
import ClassTimeline from "./classTimeline";
import SettingsIcon from "@mui/icons-material/Settings";
import { Tooltip } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import {
  Card,
  HStack,
  IconButton,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { getTimetablesByGroupId } from "@app/api/timetable/timetableApi";

const CardLectureInfo = ({
  timetable,
  group,
  modal_func,
  prop_tables,
  prop_weekday,
}) => {
  const { t } = useTranslation();

  const periodString = {
    1: t("timetable.addtimetable.select.1stclass"),
    2: t("timetable.addtimetable.select.2ndclass"),
    3: t("timetable.addtimetable.select.3rdclass"),
    4: t("timetable.addtimetable.select.4thclass"),
    5: t("timetable.addtimetable.select.5thclass"),
    6: t("timetable.addtimetable.select.6thclass"),
    7: t("timetable.addtimetable.select.7thclass"),
    8: t("timetable.addtimetable.select.8thclass"),
  };

  const user = useSelector((state) => state.user);
  const queryClient = useQueryClient();
  const history = useHistory();
  const [isLargerThan1155] = useMediaQuery("(min-width: 1156px)");

  const navigateToClassRoom = async () => {
    if (group.groupType === "AFTER") {
      history.push({
        pathname: `/after-school/${group?.groupId}`,
        state: { group },
      });
    } else {
      const data = await getTimetablesByGroupId(group?.groupId);
      queryClient.setQueryData(["timetable", group?.groupId], data.data);
      history.push({
        pathname: `/notice/${group?.groupId}`,
        state: { group },
      });
    }
  };

  const handleSetting = (e) => {
    e.stopPropagation();
    modal_func({
      timetables: prop_tables,
      weekDay: prop_weekday,
      period: timetable.period,
      startTime: timetable.startTime,
      endTime: timetable.endTime,
      group: group,
      timetableId: timetable.timetableId,
    });
  };

  return (
    <Card
      w={"full"}
      h={"full"}
      border="1px"
      borderColor="#B1BFD3"
      boxShadow={"none"}
      onClick={() => {
        navigateToClassRoom();
      }}
      px={{ base: 2, "2xl": 4 }}
      py={{ base: 0, "2xl": 1 }}
      cursor={"pointer"}
      justifyContent={"center"}
    >
      <Stack w="full" h="full">
        <HStack w="full" justifyContent="space-between">
          <Text fontWeight={"bold"} color={"gray.500"} fontSize={"x-small"}>
            {/* {timetable.period}교시 */}
            {periodString[timetable.period]}
          </Text>
          {/* <Tooltip
            // title="해당 수업 정보를 수정 하실 수 있습니다."
            // title={t("timetable.hover.text.description")}
            // arrow
          > */}
          <IconButton
            variant="ghost"
            colorScheme="gray"
            color="gray.300"
            icon={<SettingsIcon fontSize="small" />}
            onClick={handleSetting}
            size="xs"
            mr={-1}
          />
          {/* </Tooltip> */}
        </HStack>
        <HStack w="full" justifyContent="space-between">
          <Stack direction={"row"} spacing={1} noOfLines={1}>
            <Text
              fontSize={{
                md: "xs",
                lg: "xs",
                xl: "sm",
              }}
              fontWeight={"bold"}
              whiteSpace="nowrap"
              overflow={"hidden"}
            >
              {group.grade} -{" "}
              {group.groupName.length > 3
                ? group.groupName.substring(0, 3) + "..."
                : group.groupName}
            </Text>
            <Tooltip title={group.subjectName} arrow>
              <Text
                fontSize={{
                  md: "xs",
                  lg: "xs",
                  xl: "sm",
                }}
                fontWeight={"bold"}
                whiteSpace="nowrap"
                overflow={"hidden"}
              >
                {group.subjectName.length > 8
                  ? group.subjectName.substring(0, 8) + "..."
                  : group.subjectName}
              </Text>
            </Tooltip>
          </Stack>
        </HStack>
        {/* <Text
          fontSize={"xs"}
          color={"gray.500"}
          fontWeight={"nomal"}
          display={{
            base: "none",
            md: "none",
            lg: "none",
            xl: "block",
          }}
        > */}
        {/* 시작 시간 */}
        {/* </Text> */}
        <HStack spacing={1} w="full" justifyContent="space-between">
          <ClassTimeline
            // period={timetable.period}
            startTimeString={`${timetable.startTime}`}
            endTimeString={`${timetable.endTime}`}
          />
        </HStack>
      </Stack>
    </Card>
  );
};

export default CardLectureInfo;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuizsetByQuizsetId } from "@app/api/quiz/hooks/useQuizset";
import {
  Box,
  SimpleGrid,
  Stack,
  VStack,
  Spinner,
  Heading,
} from "@chakra-ui/react";
import QuizEditor from "./QuizEditor";
import QuizsetEditTitle from "@app/components/Quizset/QuizsetEditTitle";
import QuizsetGroupCreationCard from "@app/components/Quizset/QuizsetGroupCreationCard";
import QuizViewer from "./QuizViewer";
import QuizGroupCard from "./QuizGroupCard";
import { useTranslation } from "react-i18next";

const QuizSplitView = () => {
  const initialGroups = [
    { testCrewType: "A", quizzes: [], editMode: false, visible: false },
    { testCrewType: "B", quizzes: [], editMode: false, visible: false },
    { testCrewType: "C", quizzes: [], editMode: false, visible: false },
  ];

  const [groups, setGroups] = useState(initialGroups);
  const [header, setHeader] = useState("");
  const { quizsetId } = useParams();
  const { data: quizsetData, refetch, isLoading } = useQuizsetByQuizsetId(
    quizsetId
  );

  useEffect(() => {
    refetch();

    if (quizsetData && quizsetData.quizzes) {
      const quizzes = JSON.parse(quizsetData.quizzes);
      const groupMap = {};
      let lastVisibleGroupInQuizzes = "A";

      quizzes.forEach((quiz) => {
        const crewType = quiz.testCrewType;
        lastVisibleGroupInQuizzes =
          crewType > lastVisibleGroupInQuizzes
            ? crewType
            : lastVisibleGroupInQuizzes;
        if (!groupMap[crewType]) {
          groupMap[crewType] = [];
        }
        groupMap[crewType].push(quiz);
      });

      const updatedGroups = groups.map((group) => {
        const groupQuizzes = groupMap[group.testCrewType] || [];
        return {
          ...group,
          quizzes: groupQuizzes,
          visible:
            group.testCrewType <= lastVisibleGroupInQuizzes || group.visible,
        };
      });

      setGroups(updatedGroups);
    }
  }, [quizsetId, quizsetData]);

  const addGroup = () => {
    const nextGroupIndex = groups.findIndex((group) => !group.visible);
    if (nextGroupIndex === -1) {
      alert("더 이상 그룹을 생성할 수 없습니다. 최대 그룹 개수는 3개 입니다.");
      return;
    }

    const newGroups = [...groups];
    newGroups[nextGroupIndex].visible = true;
    setGroups(newGroups);
  };

  const handleAddQuiz = (groupIndex) => {
    const newGroups = [...groups];
    newGroups[groupIndex] = {
      ...newGroups[groupIndex],
      editMode: true,
      quizId: null,
    };
    setGroups(newGroups);
  };
  const { t } = useTranslation();
  if (isLoading) {
    return (
      <VStack>
        <Spinner />
        {/* <Heading>퀴즈를 불러오고 있습니다.</Heading> */}
        <Heading>{t("quiz.loading")}</Heading>
      </VStack>
    );
  }

  return (
    <Box w={"full"} h={"full"} overflow={"auto"}>
      <Stack direction="column" spacing={4} w={"full"}>
        <QuizsetEditTitle
          quizsetData={quizsetData}
          header={header}
          setHeader={setHeader}
        />
        <SimpleGrid
          columns={{ base: 1, md: 1, lg: 1, xl: 2 }}
          spacing={10}
          w={"full"}
        >
          {groups.map(
            (group, index) =>
              group.visible && (
                <QuizGroupCard
                  key={index}
                  testCrewType={group.testCrewType}
                  onAddQuiz={() => {
                    handleAddQuiz(index);
                  }}
                  editMode={group.editMode}
                >
                  {group.editMode ? (
                    <QuizEditor
                      quizsetType={"ABTEST"}
                      editMode={group.editMode}
                      setEditMode={(edit) => {
                        const newGroups = [...groups];
                        newGroups[index].editMode = edit;
                        setGroups(newGroups);
                      }}
                      quizId={group.quizId}
                      setQuizId={(id) => {
                        let newGroups = [...groups];
                        newGroups[index].quizId = id;
                        setGroups(newGroups);
                      }}
                      header={header}
                      testCrewType={group.testCrewType}
                    />
                  ) : (
                    <QuizViewer
                      editMode={group.editMode}
                      testCrewType={group.testCrewType}
                      setEditMode={(edit) => {
                        const newGroups = [...groups];
                        newGroups[index].editMode = edit;
                        setGroups(newGroups);
                      }}
                      setQuizId={(id) => {
                        const newGroups = [...groups];
                        newGroups[index].quizId = id;
                        setGroups(newGroups);
                      }}
                      onQuizSelect={(quizId) => {
                        const newGroups = [...groups];
                        newGroups[index] = {
                          ...newGroups[index],
                          editMode: true,
                          quizId,
                        };
                        setGroups(newGroups);
                      }}
                    />
                  )}
                </QuizGroupCard>
              )
          )}
          <QuizsetGroupCreationCard onAddGroup={addGroup} />
        </SimpleGrid>
      </Stack>
    </Box>
  );
};

export default QuizSplitView;

import React, { Fragment } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Divider,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import SidebarWithHeader from "./Sidebar";

const Wrapper = ({
  children,
  buttons,
  prevPageName,
  prevPageRoute,
  currentPageName,
}) => {
  return (
    <SidebarWithHeader>
      <Container maxW="full" bg={"white"}>
        <Stack direction={"column"} spacing={4}>
          <HStack w={"full"} spacing={4} mt={12}>
            <Breadcrumb>
              {prevPageName && (
                <BreadcrumbItem>
                  <BreadcrumbLink href={prevPageRoute}>
                    <Text fontSize={"3xl"}>{prevPageName}</Text>
                  </BreadcrumbLink>
                </BreadcrumbItem>
              )}
              <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink>
                  <Text fontSize={"3xl"} fontWeight={"bold"}>
                    {currentPageName}
                  </Text>
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            <HStack spacing={2}>
              {buttons &&
                buttons.map((item, index) => (
                  <Fragment key={index}>{item}</Fragment>
                ))}
            </HStack>
          </HStack>
          <Divider w={"full"} color="gray.200" />
          {children}
        </Stack>
      </Container>
    </SidebarWithHeader>
  );
};

export default Wrapper;

import { Heading, Select } from "@chakra-ui/react";
import React from "react";
import { quizTypeToText, quizTypes } from "./QuizTypeData";
import QuizTypeWrapper from "./Common/QuizTypeWrapper";
import { useTranslation } from "react-i18next";

const QuizType = ({
  setSampleAnswerType,
  setSampleAnswer,
  setSampleAnswerImageFile,
  setChoices,
  quizTypeValue,
  onChangeSelect,
}) => {
  const selectChoiceType = (type) => {
    if (type === "MULTIPLE" || type === "SHORT") {
      setChoices([{ description: "", point: 1 }]);
    }
    if (type === "OX") {
      setChoices([
        { description: "O", point: 0 },
        { description: "X", point: 0 },
      ]);
    }
    if (type === "STYLUS") {
      setSampleAnswerType("IMG");
    } else if (type === "LONG") {
      setSampleAnswerType("TXT");
    } else {
      setSampleAnswerType(null);
    }
    setSampleAnswerImageFile(null);
    setSampleAnswer("");
  };

  const { t } = useTranslation();

  const renderQuizTypeText = (quizType) => {
    switch (quizType) {
      case "OX":
        return "O/X";
      case "MULTIPLE":
        return t(
          "quizpang.quizbattlemanage.bringquestion.search.filter.selecttypemultiplechoice"
        );
      case "SHORT":
        // return "단답형";
        return t(
          "quizpang.quizbattlemanage.bringquestion.search.filter.selectshortanswer"
        );
      case "STYLUS":
        // return "필기형";
        return t(
          "quizpang.quizbattlemanage.bringquestion.search.filter.selectpen"
        );
      case "LONG":
        // return "서술형";
        return t(
          "quizpang.quizbattlemanage.bringquestion.search.filter.selectlonganswer"
        );
      default:
        return null;
    }
  };
  return (
    <QuizTypeWrapper>
      {/* <Heading fontSize={"lg"}>2. 문제 유형</Heading> */}
      <Heading fontSize={"lg"}>
        {t("quizpang.quizbattlemanage.questioncard.text.type")}
      </Heading>

      <Select
        // placeholder="퀴즈 타입을 선택하세요."
        placeholder={t("quizpang.quizbattlemanage.questioncard.type.select")}
        width={219}
        value={quizTypeValue ?? "MULTIPLE"}
        onChange={(e) => {
          const value = e.target.value === "" ? "MULTIPLE" : e.target.value;
          onChangeSelect(value);
          selectChoiceType(value);
        }}
      >
        {quizTypes?.map((x) => {
          return (
            <option key={x} value={x}>
              {renderQuizTypeText(x)}
            </option>
          );
        })}
      </Select>
    </QuizTypeWrapper>
  );
};

export default QuizType;

export const selectReport = state => {
  return state.reportList;
};

export const selectReportList = state => {
  if (state.reportList.allIds.length === 0) {
    return [];
  }
  return state.reportList.allIds
    .filter(id => id !== null)
    ?.filter(
      id => state.reportList.byId[id].content !== "com.sec.android.app.launcher"
    )

    .map(groupId => state.reportList.byId[groupId]);
};

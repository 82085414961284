import { profileImgPath } from "@api";
import { setSocketData } from "@app/store/actions";
import { selectActiveClassroom } from "@app/store/selectors";
import {
  AspectRatio,
  Avatar,
  Box,
  Card,
  CardBody,
  CardFooter,
  Flex,
  HStack,
  Heading,
  Image,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
const StudentThumnail = ({
  images,
  student,
  state,
  selectedStudent,
  setSelectedStudent,
  setSelectedImage,
  sliderColumnNumber,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));

  const studentFocusStatusList = useSelector(
    (state) => state.control.studentFocusStatusList
  );
  const studentGoodFocusStatusList = useSelector(
    (state) => state.control.studentGoodFocusStatusList
  );

  const imageKey =
    images !== undefined &&
    images !== null &&
    Object.keys(images).find((key) => key === student?.clientId);

  const [networkStable, setNetworkStable] = useState(true);

  const myProfileImage = useMemo(() => {
    if (!student) {
      return undefined;
    }
    return profileImgPath(student?.clientId);
  }, [student]);

  useEffect(() => {
    if (selectedStudent === student?.clientId) {
      dispatch(
        setSocketData({
          method: "POST",
          uri: "/classroom/sendImage",
          type: "SELECT_STUDENT",
          clientId: user?.clientId,
          groupId: activeClassroom?.groupId,
          data: student?.clientId,
        })
      );
      setSelectedImage(images[imageKey]);
    }
  }, [selectedStudent, student, images, setSelectedImage]);

  useEffect(() => {
    setNetworkStable(true);
    const timer = setTimeout(() => {
      setNetworkStable(false);
    }, 30000);
    return () => clearTimeout(timer);
  }, [images]);

  return (
    <Card
      variant={
        {
          offline: "filled",
        }[state] || "outline"
      }
      boxShadow={
        studentGoodFocusStatusList?.includes(student?.clientId)
          ? "0px 5px 5px 5px rgba(0, 255, 0, 0.5)" // 초록
          : studentFocusStatusList?.includes(student?.clientId)
          ? "0px 5px 5px 5px rgba(255, 165, 0, 0.5)" //주황
          : "0px 1px 3px 1px rgba(0, 0, 0, 0.15)"
      }
      transition={"box-shadow 0.3s ease-in-out"}
      onClick={() => {
        if (selectedStudent === student?.clientId) {
          dispatch(
            setSocketData({
              method: "POST",
              uri: "/classroom/sendImage",
              type: "SELECT_STUDENT",
              clientId: user?.clientId,
              groupId: activeClassroom?.groupId,
              data: user?.clientId,
            })
          );
          setSelectedStudent(null);
        } else {
          setSelectedStudent(student?.clientId);
        }
      }}
      cursor={"pointer"}
    >
      <CardBody p={0}>
        {images !== undefined && images !== null ? (
          images[imageKey] ? (
            <AspectRatio ratio={16 / 9}>
              <Image
                borderTopRadius="lg"
                src={`data:image/webp;base64,${images[imageKey]}`}
                style={{
                  objectFit: "cover",
                  width: "100%",
                  height: "100%",
                  objectPosition: "center center",
                }}
                alt={imageKey}
              />
            </AspectRatio>
          ) : (
            <VStack h="full" justifyContent="center">
              {/* <Text align="center">학생 화면을 불러오고 있습니다.</Text> */}
              <Text align="center">
                {t("timetable.focustime.screen.thumbnails.loading")}
              </Text>
              <Spinner />
            </VStack>
          )
        ) : (
          <VStack h="full" justifyContent="center"></VStack>
        )}
      </CardBody>

      <CardFooter
        p={
          sliderColumnNumber && sliderColumnNumber >= 3
            ? 1
            : sliderColumnNumber && sliderColumnNumber < 3
            ? 2
            : 3
        }
      >
        <HStack>
          {sliderColumnNumber && sliderColumnNumber < 3 && (
            <Avatar src={myProfileImage} name={student?.userName} />
          )}
          {sliderColumnNumber && sliderColumnNumber < 5 && (
            <Box>
              <Text
                fontSize={{
                  base: "xs",
                  md: "xs",
                  lg: "md",
                }}
                fontWeight={"bold"}
                noOfLines={1}
                width={"72px"}
                textOverflow={"ellipsis"}
              >
                {student?.userName}
              </Text>
              <Text noOfLines={1}>{student?.studentNumber}</Text>
            </Box>
          )}
        </HStack>
      </CardFooter>
    </Card>
  );
};

export default StudentThumnail;

import {
  Box,
  Checkbox,
  HStack,
  Image,
  Spinner,
  Stack,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import ICONS from "@images/appIcons/index.js";
import imgAppIcon from "@images/home/img_notice.png";
import { useProgramconfigs } from "@app/hooks/queries/programconfigs/useProgramConfigs";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ModalClassManagementAddAppBody = ({
  programs,
  groupId,
  setCheckedPrograms,
}) => {
  const user = useSelector((state) => state.user);
  const programconfigData = useProgramconfigs(groupId);

  const { t, i18n } = useTranslation();

  if (programconfigData.isLoading) {
    return (
      <VStack spacing={2}>
        <Spinner />
        <Text>앱/웹 제어 설정을 불러오는 중입니다.</Text>
      </VStack>
    );
  }

  const programconfigs = programconfigData?.data?.data.programconfigs;

  const programconfigsWithProgram = programconfigs?.map((programconfig) => {
    return {
      ...programconfig,
      ...programs[programconfig.programId],
    };
  });

  return (
    <Stack w="full" direction="column" spacing={2}>
      {programs?.map((program) => {
        return (
          <Box
            bg="white"
            borderRadius="lg"
            p="4"
            border="1px"
            borderColor="gray.200"
            key={program.programId}
          >
            <HStack spacing={2}>
              <Image boxSize="28px" src={ICONS[program.alias] || imgAppIcon} />
              <Tooltip
                label={
                  i18n.language === "ko" ? program.korName : program.engName
                }
                placement="top"
              >
                <Text
                  fontWeight="bold"
                  fontSize="sm"
                  w={{
                    base: "full",
                    sm: "8",
                    md: "14",
                    lg: "16",
                    xl: "full",
                  }}
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                  whiteSpace={"nowrap"}
                >
                  {i18n.language === "ko" ? program.korName : program.engName}
                </Text>
              </Tooltip>
              <HStack>
                <Text fontSize="xs">
                  {program.android
                    ? program.web
                      ? "Web, Android, Chormebook, MS Windows"
                      : "Android"
                    : program.web
                    ? "Web, Chromebook, MS Windows"
                    : null}
                </Text>
                <Checkbox
                  size={{
                    sm: "sm",
                    md: "md",
                    lg: "lg",
                    xl: "lg",
                  }}
                  defaultChecked={programconfigsWithProgram?.find(
                    (programconfig) =>
                      programconfig?.programId === program?.programId
                  )}
                  onChange={(e) => {
                    setCheckedPrograms((prev) => {
                      const newCheckedPrograms = [...prev];
                      const isExistingProgram = programconfigsWithProgram?.some(
                        (programconfig) =>
                          programconfig?.programId === program?.programId
                      );

                      const programData = {
                        programId: program?.programId,
                        clientId: user?.clientId,
                        groupId: groupId,
                        isAllowed: e.target.checked,
                        dayMax: 0,
                        ...(isExistingProgram && {
                          programconfigId: programconfigsWithProgram?.find(
                            (programconfig) =>
                              programconfig?.programId === program?.programId
                          )?.programconfigId,
                        }),
                        listType: isExistingProgram ? "existing" : "new",
                      };

                      const index = newCheckedPrograms?.findIndex(
                        (checkedProgram) =>
                          checkedProgram?.programId === program?.programId
                      );

                      if (index === -1) {
                        newCheckedPrograms.push(programData);
                      } else {
                        newCheckedPrograms[index] = programData;
                      }

                      return newCheckedPrograms;
                    });
                  }}
                />
              </HStack>
            </HStack>
          </Box>
        );
      })}
    </Stack>
  );
};

export default ModalClassManagementAddAppBody;

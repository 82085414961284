import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectStudentByClientId } from "@app/store/selectors";
import styles from "./CardReport.module.scss";
import momentTimezone from "moment-timezone";
import { useTranslation } from "react-i18next";

const CardReport = ({ studentName, appName, time, status }) => {
  const stdName = useSelector((state) =>
    selectStudentByClientId(state, studentName)
  );

  const momentTime = momentTimezone(time).format("YY/MM/DD HH:mm");
  const { t } = useTranslation();
  return (
    <div className={styles["cotainer"]}>
      <div className={styles["row-time"]}>{momentTime}</div>
      <div className={styles["row-name"]}>{stdName?.userName}</div>
      <div className={styles["row-app"]}>{appName}</div>
      <div className={styles["row-info"]}>
        {status === "ETC"
          ? // ? "기타"
            t("webapplimit.group.report.category.etc")
          : status === "ALLOWED"
          ? // ? "허용중"
            t("webapplimit.group.report.category.allow")
          : status === "DISALLOWED"
          ? // ? "차단중 시도"
            t("webapplimit.group.report.category.attempt")
          : ""}
      </div>
    </div>
  );
};

export default CardReport;

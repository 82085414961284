import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { HStack, IconButton, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

const QuizScoreEditor = ({
  score,
  index,
  increase,
  decrease,
  decreaseDisabled = false,
}) => {
  const { t } = useTranslation();
  return (
    <HStack spacing={4}>
      <IconButton
        disabled={decreaseDisabled}
        aria-label="점수 하락"
        icon={<MinusIcon />}
        onClick={() => decrease(index)}
      />
      <Text
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        noOfLines={1}
      >
        {score}
        {/* 점 */}
        {t(score <= 1 ? "point" : "point_plural")}
      </Text>
      <IconButton
        aria-label="점수 상승"
        icon={<AddIcon />}
        onClick={() => increase(index)}
      />
    </HStack>
  );
};

export default QuizScoreEditor;

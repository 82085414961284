import {
  CloseButton,
  HStack,
  Heading,
  IconButton,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import QuizScoreEditor from "./Common/QuizScoreEditor";
import QuizTypeWrapper from "./Common/QuizTypeWrapper";
import { useTranslation } from "react-i18next";

const QuizTypeTrueFalse = ({ choices, setChoices }) => {
  const increaseScore = useCallback(
    (index) => {
      setChoices((prev) => {
        let newChoices = [...prev];
        newChoices[index] = {
          description: newChoices[index].description,
          point: ++newChoices[index].point,
        };
        return newChoices;
      });
    },
    [setChoices]
  );
  const decreaseScore = useCallback(
    (index) => {
      setChoices((prev) => {
        let newChoices = [...prev];
        newChoices[index] = {
          description: newChoices[index].description,
          point: --newChoices[index].point,
        };
        return newChoices;
      });
    },
    [setChoices]
  );
  const TFchoiseArr = [
    { icon: <PanoramaFishEyeIcon /> },
    { icon: <CloseButton /> },
  ];
  const { t } = useTranslation();
  return (
    <QuizTypeWrapper>
      {/* <Heading fontSize={"lg"}>3. 보기 입력</Heading> */}
      <Heading fontSize={"lg"}>
        {t("quizpang.quizbattlemanage.questioncard.text.choice")}
      </Heading>
      {choices.map((choice, index) => (
        <HStack spacing={4} key={index}>
          <IconButton
            aria-label={choice.description}
            icon={TFchoiseArr[index].icon}
            variant="outline"
          />
          <QuizScoreEditor
            score={choice.point}
            index={index}
            increase={increaseScore}
            decrease={decreaseScore}
          />
        </HStack>
      ))}
    </QuizTypeWrapper>
  );
};

export default QuizTypeTrueFalse;

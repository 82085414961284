import styled from "@emotion/styled";

const BaseSelect = styled.select`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 54px;
  transition: border-color 0.2s ease;
  padding: 14px;
  border-radius: 4px 4px 4px 4px;

  appearance: none;
  background: url("/assets/icons/triangle.png") no-repeat right 16px center;
  background-size: 11px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
    border: 1px solid;
    border-color: var(--primary);
  }
`;

export default BaseSelect;

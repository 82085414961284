import {
  useCreateQuiz,
  usePatchQuizset,
  useQuizsetByQuizsetId,
} from "@app/api/quiz/hooks/useQuizset";
import QuizTypeMultiplePreview from "@app/components/Quiz/Common/QuizTypeMultiplePreview";
import QuizTypeLongPreview from "@app/components/Quiz/Common/QuizTypeLongPreview";
import QuizTypeShortPreview from "@app/components/Quiz/Common/QuizTypeShortPreview";
import QuizTypeStylusPreview from "@app/components/Quiz/Common/QuizTypeStylusPreview";
import QuizTypeOXPreview from "@app/components/Quiz/Common/QuizTypeOXPreview";
import { Button, Card, Heading, Spinner, VStack, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  closestCenter,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
  arrayMove,
} from "@dnd-kit/sortable";
import SortableQuizPreviewCard from "./SortableQuizPreviewCard";
import { useTranslation } from "react-i18next";
const QuizViewer = ({ setEditMode, setQuizId, testCrewType }) => {
  const { quizsetId } = useParams();
  const [activeId, setActiveId] = useState(null);
  const [quizArr, setQuizArr] = useState([]);
  const [quizArrForSort, setQuizArrForSort] = useState([]);
  const { data: quiz, isLoading, refetch } = useQuizsetByQuizsetId(quizsetId);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 15,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  useEffect(() => {
    refetch();
  }, [quizsetId, refetch]);
  useEffect(() => {
    if (quiz?.quizzes) {
      const parseQuizData = (quizData) => {
        const parsedQuizData = JSON.parse(quizData);
        return parsedQuizData;
      };
      if (!testCrewType) {
        setQuizArr(parseQuizData(quiz.quizzes));
        return;
      }
      let processedQuizzes;
      const filterQuizzesByTestCrewType = (quizzes, testCrewType) => {
        return quizzes.filter((quiz) => quiz.testCrewType === testCrewType);
      };
      processedQuizzes = filterQuizzesByTestCrewType(
        parseQuizData(quiz.quizzes),
        testCrewType
      );
      setQuizArr(processedQuizzes);
      setQuizArrForSort(parseQuizData(quiz.quizzes));
    }
  }, [testCrewType, quiz?.quizzes]);
  const { mutateAsync: createQuiz } = useCreateQuiz();
  const { mutateAsync: patchQuizset } = usePatchQuizset();
  const { t } = useTranslation();
  if (isLoading) {
    return (
      <VStack>
        <Spinner />
        {/* <Heading>퀴즈를 불러오고 있습니다.</Heading> */}
        <Heading>{t("quiz.loading")}</Heading>
      </VStack>
    );
  }

  const handleDragStart = (e) => {
    setActiveId(e.active.id);
  };
  const handleDragEnd = async (e) => {
    const { active, over } = e;

    if (active.id !== over.id) {
      const oldIndex = quizArr.findIndex(
        (item) => String(item.quizId) === active.id
      );
      const newIndex = quizArr.findIndex(
        (item) => String(item.quizId) === over.id
      );
      try {
        const newQuizArr = arrayMove(quizArr, oldIndex, newIndex);
        setQuizArr(newQuizArr);
        let mappedId;
        if (testCrewType) {
          const oldIndexForSort = quizArrForSort.findIndex(
            (item) => String(item.quizId) === active.id
          );
          const newIndexForSort = quizArrForSort.findIndex(
            (item) => String(item.quizId) === over.id
          );
          const newQuizArrForSort = arrayMove(
            quizArrForSort,
            oldIndexForSort,
            newIndexForSort
          );
          setQuizArrForSort(newQuizArrForSort);
          mappedId = newQuizArrForSort.map((quiz) => {
            return { quizId: quiz.quizId };
          });
        } else {
          mappedId = newQuizArr.map((quiz) => {
            return { quizId: quiz.quizId };
          });
        }
        await patchQuizset({
          quizsetId: quizsetId,
          quizzes: JSON.stringify(mappedId),
        });
      } catch (error) {
        console.log("레이아웃 수정 실패", error);
      }
    }
    setActiveId(null);
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <SortableContext
        items={quizArr.map(({ quizId }) => String(quizId))}
        strategy={verticalListSortingStrategy}
      >
        {quizArr.map((quiz) => (
          <SortableQuizPreviewCard
            key={quiz.quizId}
            quiz={quiz}
            setQuizId={setQuizId}
            setEditMode={setEditMode}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};

export default QuizViewer;

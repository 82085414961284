import { Heading, Stack } from "@chakra-ui/react";
import React from "react";
import MultipleTypeSelect from "./SurveyType/Multiple/MultipleTypeSelect";
import TrueFalse from "./SurveyType/TrueFalse/TrueFalse";
import { useTranslation } from "react-i18next";

const SurveyTypeEditor = ({
  surveyType,
  multipleType,
  handleMultipleTypeChange,
  choices,
  handleChoicesAddClick,
  handleChoicesDeleteClick,
  handlChoiceDescriptionChange,
  handleEmptyChoices,
  radioSetChoices,
}) => {
  const renderSurveyType = (surveyType) => {
    switch (surveyType) {
      case "MULTIPLE":
        return (
          <MultipleTypeSelect
            value={multipleType}
            handleMultipleTypeChange={handleMultipleTypeChange}
            choices={choices}
            handleChoicesAddClick={handleChoicesAddClick}
            handleChoicesDeleteClick={handleChoicesDeleteClick}
            handlChoiceDescriptionChange={handlChoiceDescriptionChange}
            handleEmptyChoices={handleEmptyChoices}
            radioSetChoices={radioSetChoices}
          />
        );
      case "OX":
        return <TrueFalse choices={choices} />;
      default:
        return null;
    }
  };
  const { t } = useTranslation();
  return (
    <Stack spacing={4} w="full" overflow="hidden">
      {/* <Heading fontSize={"lg"}>3. 보기입력</Heading> */}
      <Heading fontSize={"lg"}>
        {t("quizpang.quizbattlemanage.questioncard.text.choice")}
      </Heading>
      {renderSurveyType(surveyType)}
    </Stack>
  );
};

export default SurveyTypeEditor;

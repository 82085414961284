import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectGroupFilelinks } from "@store/selectors";
import styles from "./FileLinkSide.module.scss";
import CardFileLink from "../Card/CardFileLink";
import { createFilelinkPromise, setSocketData } from "@store/actions";
import classNames from "classnames";
import CollapseCardAfter from "@components/Card/CollapseCardAfter";
import BaseInput from "@components/Input/BaseInput";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const FileLinkSide = ({ group }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const [linkValid, setLinkValid] = useState(false);
  const dispatch = useDispatch();
  const groupFileLinks = useSelector((state) =>
    selectGroupFilelinks(state, group?.groupId)
  );
  const handleLinkChange = (e) => {
    const link = e.target.value;
    const regex = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;

    if (regex.test(link)) {
      setLinkValid(true);
    } else {
      setLinkValid(false);
    }
  };
  const onSubmit = async (e) => {
    const link = e.target.link.value;
    const title = e.target.title.value;
    var confirmLink = "";

    e.preventDefault();
    if (link.substr(0, 8) !== "https://" && link.substr(0, 7) !== "http://") {
      confirmLink = "http://" + link;
    } else {
      confirmLink = link;
    }

    // if (!linkValid) {
    //   alert("올바른 링크 형식이 아닙니다. 링크를 다시 확인해주세요");
    //   return;
    // }
    const linkData = {
      groupId: group?.groupId,
      title: title,
      link: confirmLink,
    };

    e.target.link.value = "";
    e.target.title.value = "";

    dispatch(createFilelinkPromise(linkData)).then((res) => {
      dispatch(
        setSocketData({
          method: "POST",
          uri: "/classroom/sendImage",
          groupId: group.groupId,
          clientId: user.clientId,
          type: "CREATE_FILELINK",
          data: "",
        })
      );
    });
  };

  return (
    <>
      <CollapseCardAfter
        title={
          <div className={styles["file-link-title"]}>
            {/* 학생에게 링크 보내기 */}
            {t("webapplimit.group.link.titile")}
          </div>
        }
        initialOpen={true}
      >
        <div
          name="fileLinkContainer"
          className={styles["file-link-container-column"]}
        >
          <form onSubmit={onSubmit} className={styles["form-link"]}>
            <BaseInput
              type="text"
              name="link"
              placeholder="url"
              onChange={handleLinkChange}
              className={styles["input-link"]}
            />
            <div className={styles["form-row"]}>
              <BaseInput
                type="text"
                name="title"
                // placeholder="링크 제목 입력"
                placeholder={t("webapplimit.group.link.placeholder")}
                className={classNames(
                  styles["input-title"]
                  // linkValid && styles['show']
                )}
              />
              <Button
                variant="contained"
                type="submit"
                name="submit"
                disabled={linkValid === false}
              >
                {/* 전송 */}
                {t("webapplimit.group.link.button")}
              </Button>
            </div>
          </form>
          {groupFileLinks.length > 0 && (
            <div className={styles["file-link-wrapper"]}>
              {groupFileLinks?.reverse().map((filelink) => (
                <CardFileLink
                  key={filelink.filelinkId}
                  filelink={filelink}
                  groupId={group.groupId}
                  clientId={user.clientId}
                />
              ))}
            </div>
          )}
        </div>
      </CollapseCardAfter>
    </>
  );
};

export default FileLinkSide;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuizsetByQuizsetId } from "@app/api/quiz/hooks/useQuizset";
import {
  Box,
  SimpleGrid,
  Stack,
  VStack,
  Spinner,
  Heading,
  Button,
} from "@chakra-ui/react";
import QuizEditor from "./QuizEditor";
import QuizsetEditTitle from "@app/components/Quizset/QuizsetEditTitle";

import QuizViewer from "./QuizViewer";
import { ButtonConstants } from "@app/Constants/ButtonConstants";
import { setReportLog } from "@app/store/actions";
import { useTranslation } from "react-i18next";

const QuizSingleView = ({ quizsetType }) => {
  const [header, setHeader] = useState("");
  const [quiz, setQuiz] = useState({});
  const [tempQuizId, setTempQuizId] = useState("");
  const [editMode, setEditMode] = useState(false);
  const { quizsetId } = useParams();
  const dispatch = useDispatch();
  const { data: quizsetData, refetch, isLoading } = useQuizsetByQuizsetId(
    quizsetId
  );
  useEffect(() => {
    refetch();

    if (quizsetData && quizsetData.quizzes) {
      let quizzesParsed;
      quizzesParsed = JSON.parse(quizsetData.quizzes);
      setQuiz({
        quizzes: quizzesParsed,
        editMode: false,
        quizId: null,
      });
    }
  }, [quizsetId, quizsetData]);

  const { t, i18n } = useTranslation();
  const handleAddQuiz = () => {
    setQuiz((prev) => {
      return { quizzes: prev.quizzes, quizId: null };
    });
    setEditMode(true);
  };

  if (isLoading) {
    return (
      <VStack>
        <Spinner />
        {/* <Heading>퀴즈를 불러오고 있습니다.</Heading> */}
        <Heading>{t("quiz.loading")}</Heading>
      </VStack>
    );
  }

  return (
    <Box w={"full"} h={"full"} overflow={"auto"}>
      <Stack direction="column" spacing={4} w={"full"}>
        <QuizsetEditTitle
          quizsetData={quizsetData}
          header={header}
          setHeader={setHeader}
        />
        <VStack align="flex-start">
          {!editMode && (
            <Button
              colorScheme={"blue"}
              onClick={() => {
                handleAddQuiz();
                dispatch(
                  setReportLog(ButtonConstants.MANAGING_QUIZPANG.CREATE_QUIZ)
                );
              }}
            >
              {/* 퀴즈 생성하기 */}
              {t("quizpang.quizbattlemanage.questioncard.create.button")}
            </Button>
          )}
        </VStack>
        {editMode ? (
          <QuizEditor
            quizsetType={quizsetType}
            setEditMode={(edit) => setEditMode(edit)}
            quizId={quiz.quizId}
            setQuizId={(id) =>
              setQuiz((prev) => {
                return { quizzes: prev.quizzes, quizId: id };
              })
            }
            header={header}
          />
        ) : (
          <QuizViewer
            setEditMode={(edit) => setEditMode(edit)}
            quizId={quiz.quizId}
            setQuizId={(id) => {
              setQuiz((prev) => {
                return {
                  ...prev,
                  quizId: id,
                };
              });
            }}
            onQuizSelect={(quizId) => {
              setQuiz((prev) => {
                return {
                  ...prev,
                  quizId: quiz.quizId,
                };
              });
              setEditMode(true);
            }}
          />
        )}
      </Stack>
    </Box>
  );
};

export default QuizSingleView;

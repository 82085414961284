import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Button, Text } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import QuizSplitView from "./QuizSplitView";
import QuizSingleView from "./QuizSingleView";
import SurveyView from "@app/components/SurveryForm/SurveyView";
import Wrapper from "@app/components/Layout/Common/Wrapper";
import { useTranslation } from "react-i18next";

const QuizCreateHome = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const quizsetType = window.location.pathname.split("/")[2];
  const quizTypeSwitch = (quizsetType) => {
    switch (quizsetType) {
      case "quiz-ab": {
        return <QuizSplitView />;
      }
      case "quiz": {
        return <QuizSingleView quizsetType={"QUIZ"} />;
      }
      case "survey": {
        return <SurveyView />;
      }
      default: {
        break;
      }
    }
  };
  const isSurvey = quizsetType === "survey";
  return (
    <Wrapper
      prevPageRoute={"/quiz-management"}
      // prevPageName={"퀴즈팡 관리"}
      prevPageName={t("quizpang.quizpang-management.text.title")}
      // currentPageName={!isSurvey ? "퀴즈 만들기" : "설문지 만들기"}
      currentPageName={t("quizpang.button.text")}
      buttons={[
        <Button
          leftIcon={<ChevronLeftIcon />}
          onClick={() => {
            history.goBack();
          }}
        >
          {/* <Text>뒤로가기</Text> */}
          {t("timetable.quizpang.quizbattlesection.back")}
        </Button>,
      ]}
    >
      {quizTypeSwitch(quizsetType)}
    </Wrapper>
  );
};

export default QuizCreateHome;

import {
  classGrouplist,
  classtimes,
  gradeList,
  weekdays,
} from "@app/Constants/groupTimetable";
import {
  socketKind,
  socketMethod,
  socketUrl,
} from "@app/Constants/socketConstants";
import {
  useDeleteTimetable,
  usePatchTimetable,
} from "@app/pages/Home/hooks/useTimetable";
import {
  deleteGroupPromise,
  setSocketData,
  updateGroupPromise,
} from "@app/store/actions";

import { getTimetableByGroupId } from "@app/utils/timetable/timetableUtils";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

export const ModalEditTimetable = ({
  show,
  onHide,
  timetableData,
  timetableList,
}) => {
  const { t } = useTranslation();

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [editingFields, setEditingFields] = useState({
    groupType: timetableData.groupType || "SUBJECT",
    groupName: parseInt(timetableData.groupName),
    grade: timetableData.grade,
    week: timetableData.weekDay,
    period: timetableData.period,
    startTime: timetableData.startTime,
    endTime: timetableData.endTime,
  });
  const [subjectName, setSubjectName] = useState(
    timetableData.subjectName || ""
  );

  const timetablefilter = getTimetableByGroupId(
    timetableList,
    timetableData.groupId
  );

  const onChangeEditData = (e) => {
    const { name, value } = e.target;
    setEditingFields((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const deleteTimetable = useDeleteTimetable(timetableData.timetableId);
  const updateTimetable = usePatchTimetable(editingFields);

  const onDeleteTimetable = async () => {
    try {
      await deleteTimetable.deleteTimetableAsync();
      // 만약 해당 시간표가 1개밖에 없다면, 그룹도 삭제처리
      if (timetablefilter.length === 1) {
        // dispatch를 사용한 이유는 groupList를 현재 리덕스에 저장하여 사용하고 있는 구조이기 때문\
        // TODO: groupList를 캐시로 전환 시 해당 로직 변경 필요
        await dispatch(deleteGroupPromise(timetableData.groupId));
      }
      await dispatch(
        setSocketData({
          method: socketMethod.POST,
          uri: socketUrl.classroomSendImage,
          groupId: timetableData.groupId,
          clientId: user.clientId,
          type: socketKind.timetable.CLASS_DELETE,
          data: "",
        })
      );
      onHide();
    } catch (err) {
      console.log(err);
    }
  };

  const onPatchTimetable = async () => {
    const updatedGroupData = {
      groupId: timetableData.groupId,
      subjectName: subjectName,
      groupName: editingFields.groupName,
      grade: editingFields.grade,
      groupType: editingFields.groupType,
    };
    const updatedTimetableData = {
      timetableId: timetableData.timetableId,
      weekday: editingFields.week,
      period: editingFields.period,
      startTime: editingFields.startTime,
      endTime: editingFields.endTime,
    };
    await dispatch(updateGroupPromise(updatedGroupData));
    await updateTimetable.patchTimetableAsync(updatedTimetableData);
    await dispatch(
      setSocketData({
        method: socketMethod.POST,
        uri: socketUrl.classroomSendImage,
        groupId: timetableData.groupId,
        clientId: user.clientId,
        type: socketKind.timetable.CLASS_UPDATE,
        data: "",
      })
    );
    onHide();
    window.location.reload();
  };

  const isLargerThan1034 = useMediaQuery("(min-width: 1035px)");

  return (
    <Dialog
      open={show}
      onClose={onHide}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        {/* 시간표 수정 */}
        {t("timetable.modifytimetable.text.title")}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={0}>
          <Grid xs={12} md={12}>
            <Box>
              <Typography variant="body1">
                {/* 과목명 */}
                {t("timetable.modifytimetable.text.subjectname")}
              </Typography>
              <TextField
                id="subjectName"
                name="subjectName"
                variant="outlined"
                onChange={(e) => setSubjectName(e.target.value)}
                value={subjectName}
                sx={{ width: "40%" }}
                inputProps={{
                  maxLength: 25,
                }}
              />
            </Box>
          </Grid>
          <Grid xs={4} md={4}>
            <FormControl variant="outlined" sx={{ m: 1, minWidth: "40%" }}>
              <Typography variant="body1">
                {/* 학년 */}
                {t("timetable.modifytimetable.text.grade")}
              </Typography>
              <Select
                value={editingFields.grade}
                onChange={onChangeEditData}
                name="grade"
              >
                {gradeList.map((grade, idx) => (
                  <MenuItem key={idx} value={grade[0]}>
                    {t(grade[1])}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" sx={{ m: 1, minWidth: "40%" }}>
              <Typography variant="body1">
                {/* 반 */}
                {t("timetable.modifytimetable.text.class")}
              </Typography>
              <Select
                value={editingFields.groupName}
                onChange={onChangeEditData}
                name="groupName"
              >
                {classGrouplist.map((group, idx) => (
                  <MenuItem key={idx} value={group[0]}>
                    {t(group[1])}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={4} md={4}>
            <FormControl variant="outlined" sx={{ m: 1, minWidth: "40%" }}>
              <Typography variant="body1">
                {/* 요일 */}
                {t("timetable.modifytimetable.text.week")}
              </Typography>
              <Select
                value={editingFields.week}
                onChange={onChangeEditData}
                name="week"
              >
                {weekdays.map((weekday, idx) => (
                  <MenuItem key={idx} value={weekday[0]}>
                    {t(weekday[1])}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" sx={{ m: 1, minWidth: "40%" }}>
              <Typography variant="body1">
                {/* 교시 */}
                {t("timetable.modifytimetable.text.period")}
              </Typography>
              <Select
                value={editingFields.period}
                onChange={onChangeEditData}
                name="period"
              >
                {classtimes.map((classtime, idx) => (
                  <MenuItem key={idx} value={classtime[0]}>
                    {t(classtime[1])}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={4} md={4}>
            <Typography variant="body1" sx={{ ml: 1, minWidth: "152px" }}>
              {/* 수업 시작/종료 시간 */}
              {t("timetable.modifytimetable.text.starttime")} /{" "}
              {t("timetable.modifytimetable.text.endtime")}
            </Typography>
            <TextField
              id="startTime"
              name="startTime"
              type="time"
              value={editingFields.startTime}
              onChange={onChangeEditData}
              sx={{ m: 1, minWidth: "40%" }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="endTime"
              name="endTime"
              type="time"
              value={editingFields.endTime}
              onChange={onChangeEditData}
              sx={{ m: 1, minWidth: "40%", mt: isLargerThan1034 ? 1 : 4 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onDeleteTimetable();
          }}
          variant="contained"
          style={{ minWidth: "max-content" }}
        >
          {/* 삭제 */}
          {t("timetable.modifytimetable.button.delete")}
        </Button>
        <div style={{ flexGrow: 1 }} />
        <Button
          onClick={() => {
            onHide();
          }}
          variant="contained"
        >
          {/* 취소 */}
          {t("timetable.modifytimetable.button.cancel")}
        </Button>
        <Button
          onClick={() => {
            onPatchTimetable();
          }}
          variant="contained"
          style={{ minWidth: "max-content" }}
        >
          {/* 변경 */}
          {t("timetable.modifytimetable.button.submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

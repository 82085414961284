import { useQuizByQuizId } from "@app/api/quiz/hooks/useQuizset";
import {
  Heading,
  Spinner,
  VStack,
  Stack,
  InputGroup,
  Box,
  Text,
} from "@chakra-ui/react";
import React from "react";
import ReactQuill from "react-quill";
import QuizVideoPreview from "./QuizVideoPreview";
import { useTranslation } from "react-i18next";

const QuizTypeStylusPreview = ({ quizId }) => {
  const quiz = useQuizByQuizId(quizId);

  const { t } = useTranslation();
  if (quiz.isLoading) {
    return (
      <VStack>
        <Spinner />
        {/* <Heading>퀴즈를 불러오고 있습니다.</Heading> */}
        <Heading>{t("quiz.loading")}</Heading>
      </VStack>
    );
  }

  const quizData = quiz?.data;

  const isImageUrl = (url) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  };

  return (
    <Stack
      direction="column"
      spacing={4}
      w={"full"}
      style={{
        whiteSpace: "no-wrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      <ReactQuill
        value={quizData.title}
        readOnly
        theme="bubble"
        style={{
          whiteSpace: "no-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "100%",
          maxHeight: "200px",
        }}
      />
      <QuizVideoPreview link={quizData.link} linkType={quizData.linkType} />
      <InputGroup>
        <Box
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          {isImageUrl(quizData.sampleAnswer) ? (
            <>
              <img
                src={quizData.sampleAnswer}
                alt="모범 답안 이미지"
                style={{ maxWidth: "100%", maxHeight: "300px", margin: "10px" }}
              />
            </>
          ) : (
            <ReactQuill
              value={quizData.sampleAnswer}
              readOnly
              theme="bubble"
              style={{
                overflow: "hidden",
                width: "100%",
                maxHeight: "200px",
              }}
            />
          )}
        </Box>
      </InputGroup>
    </Stack>
  );
};

export default QuizTypeStylusPreview;

import React from "react";
import { HStack, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const ClassTimeline = ({ startTimeString, endTimeString }) => {
  const { t } = useTranslation();
  // 오전인지 오후인지 텍스트로 변환해주는 함수
  const getAmPm = (time) => {
    if (time < 12) {
      return "오전";
    } else {
      return "오후";
    }
  };

  // 시간을 12시간제로 변환해주는 함수
  const convertTime = (time) => {
    if (time > 12) {
      return time - 12;
    } else {
      return time;
    }
  };

  return (
    <HStack spacing={4}>
      <Stack direction={"column"}>
        <HStack align="baseline">
          <Text fontSize={"x-small"} fontWeight={"bold"}>
            {convertTime(startTimeString)}
          </Text>
          {/* <Text fontSize={"xs"} fontWeight={"bold"}>
            {getAmPm(startTimeString)}
          </Text> */}
        </HStack>
      </Stack>
    </HStack>
  );
};

export default ClassTimeline;

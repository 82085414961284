import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  HStack,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useCreateFilelink } from "@app/api/classroom/hooks/useFilelink";
import FileLinkList from "./FileLinkList";
import {
  socketKind,
  socketMethod,
  socketUrl,
} from "@app/Constants/socketConstants";
import { setSocketData } from "@app/store/actions";
import FileList from "./FileList";
import { uploadNoticeFile } from "@api";
import { useTranslation } from "react-i18next";
// import { useTranslation } from "react-i18next";

const FileLink = ({ groupId }) => {
  // const { t } = useTranslation();

  const user = useSelector((state) => state.user);
  const [linkValid, setLinkValid] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();

  const { mutate: createFilelink } = useCreateFilelink();

  const handleLinkChange = () => {
    const linkData = link;
    const regex = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;

    if (regex.test(linkData)) {
      setLinkValid(true);
    } else {
      setLinkValid(false);
    }
  };

  const onSubmit = async (e) => {
    let confirmLink = "";

    e.preventDefault();

    if (!/^https?:\/\//i.test(link)) {
      confirmLink = "https://" + link;
    } else {
      confirmLink = link;
    }

    const linkData = {
      groupId: groupId,
      title: title,
      link: confirmLink,
    };

    createFilelink(linkData);
    dispatch(
      setSocketData({
        method: socketMethod.POST,
        uri: socketUrl.classroomSendImage,
        groupId: groupId,
        clientId: user.clientId,
        type: socketKind.classroom.CREATE_FILELINK,
        data: "",
      })
    );

    setLink("");
    setTitle("");
    setLinkValid(false);
  };

  const handleFileChange = useCallback((event) => {
    const file = event.target.files[0];
    console.log("file", file);
    if (file) {
      setSelectedFile(file);
      fileUpload();
    }
  }, []);

  const fileUpload = async () => {
    console.log("fileUpload", selectedFile);
    const fileLink = await uploadNoticeFile(selectedFile, user.clientId);

    await createFilelink({
      groupId: groupId,
      title: "첨부파일",
      link: fileLink,
    });

    dispatch(
      setSocketData({
        method: socketMethod.POST,
        uri: socketUrl.classroomSendImage,
        groupId: groupId,
        clientId: user.clientId,
        type: socketKind.classroom.CREATE_FILELINK,
        data: "",
      })
    );

    setSelectedFile(null);
  };
  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };
  const { t } = useTranslation();
  return (
    <Accordion allowMultiple>
      <AccordionItem>
        <Text>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              {/* 학생에게 파일 보내기 */}
              {t("timetable.focustime.accordion.title.sendlink")}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </Text>
        <AccordionPanel pb={4}>
          <Tabs variant="soft-rounded">
            <TabList>
              <Tab>
                {/* 링크 */}
                {t("timetable.focustime.accordion.button.sendlink")}
              </Tab>
              {/* <Tab> */}
              {/* 파일 */}
              {/* {t("timetable.focustime.accordion.button.sendfile")} */}
              {/* </Tab> */}
            </TabList>
            <TabPanels>
              <TabPanel
                h={200}
                overflowY="scroll"
                css={{
                  "&::-webkit-scrollbar": {
                    width: "4px",
                  },
                  "&::-webkit-scrollbar-track": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#ccc",
                    borderRadius: "24px",
                  },
                }}
              >
                <Stack w="full" direction="column" spacing={2}>
                  <HStack spacing={2}>
                    <Input
                      value={link}
                      // placeholder="링크를 입력해주세요."
                      placeholder={t(
                        "timetable.focustime.accordion.input.hover.description"
                      )}
                      onChange={(e) => {
                        setLink(e.target.value);
                        handleLinkChange();
                      }}
                      w="2/3"
                    />
                    {linkValid && (
                      <Input
                        value={title}
                        // placeholder="링크 제목을 입력해주세요."
                        placeholder={t(
                          "timetable.focustime.accordion.title.sendlink.placeholder"
                        )}
                        onChange={(e) => setTitle(e.target.value)}
                        w="1/3"
                      />
                    )}
                    <Button isActive={!linkValid} onClick={onSubmit}>
                      {/* 링크 공유 */}
                      {t(
                        "timetable.focustime.accordion.button.submit.sendlink"
                      )}
                    </Button>
                  </HStack>
                  <Divider color="gray.200" />
                  <Box w="full">
                    <FileLinkList groupId={groupId} />
                  </Box>
                </Stack>
              </TabPanel>
              {/* <TabPanel
                h={200}
                overflowY="scroll"
                css={{
                  "&::-webkit-scrollbar": {
                    width: "4px",
                  },
                  "&::-webkit-scrollbar-track": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#ccc",
                    borderRadius: "24px",
                  },
                }}
              >
                <Stack w="full" direction="column" spacing={2}>
                  <HStack spacing={2}>
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    <Button onClick={triggerFileInput}>
                      {t("timetable.focustime.accordion.button.addfile")}
                    </Button>
                  </HStack>
                  <Divider color="gray.200" />
                  <FileList groupId={groupId} />
                </Stack>
              </TabPanel> */}
            </TabPanels>
          </Tabs>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default FileLink;

import React, { useState } from "react";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Divider,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { PiPlugsConnectedBold } from "react-icons/pi";
import { PiPlugsBold } from "react-icons/pi";
import { useSelector } from "react-redux";
import StudentThumnail from "@app/pages/NewClassroom/StudentThumnail";
import { useTranslation } from "react-i18next";
import { IoGridOutline } from "react-icons/io5";

const ModalStudentFullScreen = ({
  onClose,
  isOpen,
  participationStudentList,
  offlineStudents,
}) => {
  const { t } = useTranslation();

  const { images: stdImages } = useSelector((state) => state.control);

  const [sliderColumnNumber, setSliderColumnNumber] = useState(2);
  return (
    <Modal
      size={"full"}
      onClose={onClose}
      isOpen={isOpen}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Container maxW="full" p="8">
            <Stack spacing={4} w={"full"}>
              <Breadcrumb>
                <BreadcrumbItem>
                  <BreadcrumbLink>
                    <Text fontSize={"2xl"} fontWeight={"bold"}>
                      {/* 모든 학생 화면 보기 */}
                      {t(
                        "timetable.focustime.studentlist.allstudent.view.title"
                      )}
                    </Text>
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>
              <HStack spacing={2} w={"full"} justifyContent={"space-between"}>
                <Button onClick={onClose}>
                  {/* 닫기 */}
                  {t(
                    "timetable.focustime.studentlist.allstudent.view.close.button"
                  )}
                </Button>
              </HStack>
              <Divider w="full" color="gray.300" />
              <Tabs variant={"line"}>
                <TabList>
                  <Tab>
                    <HStack spacing={2}>
                      <Icon as={PiPlugsConnectedBold} />
                      <Text>
                        {/* 온라인 */}
                        {t("timetable.focustime.studentlist.online")}
                      </Text>
                      <Text fontSize="lg" fontWeight="bold">
                        {participationStudentList?.length}
                        {t("focustime.text.people")}
                      </Text>
                    </HStack>
                  </Tab>
                  <Tab>
                    <HStack spacing={2}>
                      <Icon as={PiPlugsBold} />
                      <Text>
                        {/* 오프라인 */}
                        {t("timetable.focustime.studentlist.offline")}
                      </Text>
                      <Text fontSize="lg" fontWeight="bold">
                        {offlineStudents?.length}
                        {t("focustime.text.people")}
                      </Text>
                    </HStack>
                  </Tab>
                </TabList>

                <Box p={4}>
                  <Slider
                    aria-label="grid-column-slice"
                    min={2}
                    max={6}
                    defaultValue={2}
                    onChange={(val) => setSliderColumnNumber(val)}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb boxSize={8}>
                      <Box color="#3b82f6" as={IoGridOutline} />
                    </SliderThumb>
                  </Slider>
                </Box>

                <TabPanels>
                  <TabPanel>
                    <Stack direction="column">
                      <SimpleGrid
                        columns={{ sm: 1, md: sliderColumnNumber }}
                        spacing={2}
                      >
                        {participationStudentList?.map((student, index) => (
                          <StudentThumnail
                            key={student.clientId}
                            student={student}
                            images={stdImages}
                            w="full"
                            h="full"
                            colums={4}
                            state="online"
                            sliderColumnNumber={sliderColumnNumber}
                          />
                        ))}
                      </SimpleGrid>
                      <Divider w="full" color="gray.300" />
                    </Stack>
                  </TabPanel>
                  <TabPanel>
                    <Stack direction="column">
                      <SimpleGrid
                        columns={{ sm: 1, md: sliderColumnNumber }}
                        spacing={2}
                      >
                        {offlineStudents?.map((student) => (
                          <StudentThumnail
                            key={student.clientId}
                            student={student}
                            w="full"
                            h="full"
                            colums={4}
                            state="offline"
                            sliderColumnNumber={sliderColumnNumber}
                          />
                        ))}
                      </SimpleGrid>
                    </Stack>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Stack>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalStudentFullScreen;

import { useQuizByQuizId } from "@app/api/quiz/hooks/useQuizset";
import {
  Heading,
  Spinner,
  VStack,
  Stack,
  InputGroup,
  Box,
} from "@chakra-ui/react";
import React from "react";
import ReactQuill from "react-quill";
import QuizVideoPreview from "./QuizVideoPreview";
import { useTranslation } from "react-i18next";
const QuizTypeLongPreview = ({ quizId }) => {
  console.log("Current Quiz ID:", quizId);
  const quiz = useQuizByQuizId(quizId);
  const { t } = useTranslation();
  if (quiz.isLoading) {
    return (
      <VStack>
        <Spinner />
        {/* <Heading>퀴즈를 불러오고 있습니다.</Heading> */}
        <Heading>{t("quiz.loading")}</Heading>
      </VStack>
    );
  }

  const quizData = quiz?.data;

  return (
    <VStack
      spacing={4}
      w={"full"}
      style={{
        whiteSpace: "no-wrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      <ReactQuill
        value={quizData.title}
        readOnly
        theme="bubble"
        style={{
          whiteSpace: "no-wrap",
          overflow: "hidden",
          width: "100%",
          maxHeight: "200px",
        }}
      />
      <QuizVideoPreview link={quizData.link} linkType={quizData.linkType} />
      <InputGroup>
        <Box
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          width="100%"
          style={{
            whiteSpace: "no-wrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <ReactQuill
            value={quizData.sampleAnswer}
            readOnly
            theme="bubble"
            style={{
              whiteSpace: "no-wrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
              maxHeight: "200px",
            }}
          />
        </Box>
      </InputGroup>
    </VStack>
  );
};
export default QuizTypeLongPreview;

import React from "react";
import { Image, ImageContainer } from "@app/pages/QuizPang/quiz-image";
import DeleteIcon from "@mui/icons-material/Delete";
import { Body2, Column, Row } from "@app/pages/QuizPang/common";
import styled from "styled-components";
import videoPlayIcon from "@images/quiz-pang/video-play-icon.png";
import { getYoutubeVideoThumbnail } from "@app/store/helpers";
import ImageIcon from "@mui/icons-material/Image";
import MovieIcon from "@mui/icons-material/Movie";
import { Button, HStack } from "@chakra-ui/react";
import { t } from "i18next";
export const ImageMediaInput = ({
  setLinkType,
  openModal,
  videoLink,
  removeVideoLink,
  imgPath,
  removeImage,
  setImageFile,
}) => {
  // console.log("fromInput", videoLink, imgPath());
  if (videoLink !== "NotSet" && videoLink) {
    return (
      <StyledImageContainer style={{ width: "100%" }}>
        <StyledImage src={getYoutubeVideoThumbnail(videoLink)} />
        <VideoOverlayContainer>
          <img
            src={videoPlayIcon}
            style={{ width: 55, height: 55 }}
            alt="video_play_icon"
          />
        </VideoOverlayContainer>
        <ImageOverlayContainer>
          <Button
            variant="contained"
            leftIcon={<DeleteIcon sx={{ color: "#FF5252" }} />}
            style={{ color: "#FF5252", background: "#fff" }}
            onClick={() => removeVideoLink()}
          >
            {/* 영상 삭제 */}
            {t("quizpang.quizbattlemanage.questioncard.button.video.delete")}
          </Button>
        </ImageOverlayContainer>
      </StyledImageContainer>
    );
  }
  if (imgPath()) {
    return (
      <StyledImageContainer style={{ width: "100%" }}>
        <StyledImage src={imgPath()} />
        <ImageOverlayContainer>
          <Button
            variant="contained"
            leftIcon={<DeleteIcon sx={{ color: "#FF5252" }} />}
            style={{ color: "#FF5252", background: "#fff" }}
            onClick={() => removeImage()}
          >
            {/* 이미지 삭제 */}
            {t("quizpang.quizbattlemanage.questioncard.button.image.delete")}
          </Button>
        </ImageOverlayContainer>
      </StyledImageContainer>
    );
  }
  return (
    <HStack spacing={4} w={"full"}>
      <Button onClick={() => openModal()} leftIcon={<MovieIcon />}>
        {/* 영상 링크 */}
        {t("quizpang.quizbattlemanage.questioncard.button.video")}
      </Button>
      <Button leftIcon={<ImageIcon />}>
        {/* 이미지 */}
        {t("quizpang.quizbattlemanage.questioncard.button.image")}
        <VisuallyHiddenInput
          type="file"
          onChange={(e) => {
            removeVideoLink();
            setLinkType("IMG");
            setImageFile(e.target.files[0]);
          }}
        />
      </Button>
    </HStack>
  );
};

export const StyledRow = styled(Row)`
  gap: 28px;
`;

export const ButtonBox = styled(Column)`
  width: 100px;
  height: 100px;
  background: var(--grey-lighten-4);
  border: 1px dashed var(--grey-darken-1);
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  gap: 10px;
  cursor: pointer;
`;

const TitleText = styled(Body2)`
  font-weight: 400;
  color: var(--primary-darken);
`;

export const ImagePlaceholder = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    cursor: pointer;
  }
`;

export const ImageOverlayContainer = styled(Row)`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 16px;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const VideoOverlayContainer = styled(Row)`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 16px;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
`;

const StyledImageContainer = styled(ImageContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const VisuallyHiddenInput = styled.input`
  opacity: 0;
  height: 40px;
  width: 100px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
`;

const StyledImage = styled(Image)`
  max-width: 300px;
  max-height: 300px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0 auto;
`;

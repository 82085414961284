import React from "react";
import { Box, Card, Icon, Text, VStack, useMediaQuery } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AddIcon } from "@chakra-ui/icons";
import { Tooltip } from "@mui/material";
import { AspectRatio } from "@mui/icons-material";

const CardLectureInfoEmpty = ({
  timetable,
  group,
  modal_func,
  prop_tables,
  prop_weekday,
}) => {
  const { t } = useTranslation();

  const [isLargerThan1155] = useMediaQuery("(min-width: 1156px)");
  const [isLargerThan767] = useMediaQuery("(min-width: 768px)");

  return (
    <Tooltip title={t("timetable.hover.text.description")} arrow>
      <Card
        w="full"
        // h={{
        //   base: "auto",
        //   sm: "auto",
        //   md: "auto",
        //   lg: "auto",
        //   xl: "auto",
        // }}]
        h="full"
        // minW="96.45px"
        border="1px solid"
        bg="#F2F2F2"
        borderColor="#B1BFD3"
        p={3}
        onClick={() => {
          modal_func({
            timetables: prop_tables,
            weekDay: prop_weekday,
            period: timetable.period,
          });
        }}
        boxShadow={"none"}
        cursor={"pointer"}
      >
        <VStack
          w="full"
          h="full"
          spacing={0}
          alignItems="center"
          justifyContent="center"
        >
          <Icon
            as={AddIcon}
            w={isLargerThan767 ? "24px" : "20px"}
            h={isLargerThan767 ? "24px" : "20px"}
            color="#9B9B9B"
          />
        </VStack>
      </Card>
    </Tooltip>
  );
};

export default CardLectureInfoEmpty;

import React, { useState, useEffect, useCallback } from "react";
import { profileImgPath } from "@api";
import ClassroomTimer from "@app/components/Card/CardClassroomTimer";
import {
  deleteClassroomPromise,
  getActiveClassroomPromise,
  purgeImage,
  purgeStudentFocusStatusList,
  setClassroomState,
  setScreenData,
  setSocketData,
  updateClassroomPromise,
} from "@app/store/actions";
import { selectActiveClassroom, selectGroupById } from "@app/store/selectors";
import {
  Avatar,
  Box,
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  socketKind,
  socketMethod,
  socketUrl,
} from "@app/Constants/socketConstants";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import ReplyIcon from "@mui/icons-material/Reply";
import LockIcon from "@mui/icons-material/Lock";
import { RiFocus2Fill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
// import useRTC from "@app/utils/webRTC/hooks/useRTC";

const TeacherScreenToolbar = ({
  isSharing,
  startSharing,
  stopSharing,

  isOpen,
  onOpen,
  onClose,

  selectedStudent,
  setSelectedStudent,
  selectedImage,
  setSelectedImage,
}) => {
  const user = useSelector((state) => state.user);
  const { groupId } = useParams();
  const group = useSelector((state) => selectGroupById(state, groupId));
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const dispatch = useDispatch();

  const newScreenLocked = !activeClassroom.screenLocked;
  const activeClassroomEyeTracking = activeClassroom.eyetracking;

  const [screenlockedOnceClicked, setScreenlockedOnceClicked] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isStdLocked, setisStdLocked] = useState(false);
  const [selectedStudentScreenShare, setSelectedStudentScreenShare] = useState(
    false
  );
  const [isStudentScreenShare, setIsStudentScreenShare] = useState(false);

  const sendDeletedClassroomBySocket = () => {
    dispatch(
      setSocketData({
        method: "POST",
        uri: "/classroom/sendImage",
        groupId: group?.groupId,
        clientId: user?.clientId,
        type: "CLASS_END",
        data: "",
      })
    );
  };

  const sendUpdatedClassroomBySocket = useCallback(() => {
    dispatch(
      setSocketData({
        method: "POST",
        uri: "/classroom/sendImage",
        groupId: group?.groupId,
        clientId: user?.clientId,
        type: "CLASS_UPDATE",
        data: "",
      })
    );
  }, [dispatch, group.groupId, user.clientId]);

  const clickClassEnd = () => {
    dispatch(purgeStudentFocusStatusList());
    dispatch(deleteClassroomPromise(activeClassroom?.classroomId)).then(() => {
      dispatch(setClassroomState(false));
      dispatch(purgeImage());
      sendDeletedClassroomBySocket();
      return dispatch(getActiveClassroomPromise(user.clientId));
    });
  };
  const sendFocusStartBySocket = () => {
    dispatch(
      updateClassroomPromise({
        classroomId: activeClassroom.classroomId,
        eyetracking: true,
      })
    );
    dispatch(
      setSocketData({
        method: socketMethod.POST,
        uri: socketUrl.classroomSendImage,
        groupId: group?.groupId,
        clientId: user?.clientId,
        type: socketKind.classroom.FOCUS_START,
        data: "",
      })
    );
  };
  const sendFocusEndBySocket = () => {
    dispatch(
      updateClassroomPromise({
        classroomId: activeClassroom.classroomId,
        eyetracking: false,
      })
    );
    dispatch(
      setSocketData({
        method: socketMethod.POST,
        uri: socketUrl.classroomSendImage,
        groupId: group?.groupId,
        clientId: user?.clientId,
        type: socketKind.classroom.FOCUS_END,
        data: "",
      })
    );
  };

  const handleLockScreen = () => {
    if (!activeClassroom) {
      return;
    }

    setScreenlockedOnceClicked(true);
    setTimeout(() => {
      setScreenlockedOnceClicked(false);
    }, 1000);

    dispatch(
      updateClassroomPromise({
        classroomId: activeClassroom.classroomId,
        screenLocked: newScreenLocked,
      })
    )
      .then(() => {
        if (!newScreenLocked) {
          dispatch(
            setSocketData({
              method: "POST",
              uri: "/classroom/sendImage",
              groupId: groupId,
              clientId: user?.clientId,
              type: "UNLOCK_SCREEN",
              data: "",
            })
          );
        }
        if (newScreenLocked) {
          dispatch(
            setSocketData({
              method: "POST",
              uri: "/classroom/sendImage",
              groupId: group?.groupId,
              clientId: user?.clientId,
              type: "LOCK_SCREEN",
              data: "",
            })
          );
        }
        sendUpdatedClassroomBySocket();
        setisStdLocked(!isStdLocked);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (!selectedStudentScreenShare || !selectedStudent || !selectedImage) {
      return;
    }
    dispatch(
      setScreenData({
        method: "POST",
        uri: "/classroom/sendImage",
        type: "IMAGE_TEACHER",
        clientId: user.clientId,
        groupId: activeClassroom?.groupId,
        data: selectedImage,
      })
    );
  }, [selectedStudentScreenShare, selectedStudent, selectedImage]);

  //

  useEffect(() => {
    if (isFocus) {
      sendFocusStartBySocket();
    } else {
      sendFocusEndBySocket();
      dispatch(purgeStudentFocusStatusList());
    }
  }, [isFocus]);

  const { t } = useTranslation();
  return (
    <Box bg="gray.400" p={2} borderBottomRadius="lg">
      <HStack spacing={4} justifyContent="space-between">
        <HStack>
          <HStack>
            <Avatar
              size="sm"
              name={user?.userName}
              src={profileImgPath(user?.clientId)}
            />
            <Text fontSize="xs">{user?.userName}</Text>
          </HStack>
          <ClassroomTimer />
        </HStack>
        <HStack>
          <VStack>
            <Menu direction="ltr">
              <MenuButton
                as={IconButton}
                icon={<ReplyIcon color={isSharing ? "white" : "black"} />}
                borderRadius="full"
                backgroundColor={
                  isSharing || selectedStudentScreenShare ? "#4684e7" : ""
                }
                _hover={{ backgroundColor: "#4684e7" }}
                onClick={() => {
                  if (isSharing) {
                    stopSharing();
                    dispatch(
                      setSocketData({
                        method: "POST",
                        uri: "/classroom/sendImage",
                        groupId: activeClassroom?.groupId,
                        clientId: user?.clientId,
                        type: "CLASS_UPDATE",
                        data: "",
                      })
                    );
                  }
                  if (isStudentScreenShare) {
                    stopSharing();
                    setSelectedStudentScreenShare(false);
                    dispatch(
                      updateClassroomPromise({
                        classroomId: activeClassroom?.classroomId,
                        screenShared: false,
                      })
                    );
                    dispatch(
                      setSocketData({
                        method: "POST",
                        uri: "/classroom/sendImage",
                        groupId: activeClassroom?.groupId,
                        clientId: user?.clientId,
                        type: "CLASS_UPDATE",
                        data: "",
                      })
                    );
                  }
                  if (selectedStudentScreenShare) {
                    setSelectedStudentScreenShare(false);
                    setSelectedStudent(null);
                    setSelectedImage(null);
                  }
                }}
              />

              <MenuList>
                <MenuItem
                  onClick={() => {
                    dispatch(
                      updateClassroomPromise({
                        classroomId: activeClassroom?.classroomId,
                        screenShared: true,
                      })
                    );
                    dispatch(
                      setSocketData({
                        method: "POST",
                        uri: "/classroom/sendImage",
                        groupId: activeClassroom?.groupId,
                        clientId: user?.clientId,
                        type: "CLASS_UPDATE",
                        data: "",
                      })
                    );

                    setSelectedStudentScreenShare(true);
                    setIsStudentScreenShare(true);
                  }}
                >
                  {/* <Text>선택한 학생 화면 공유</Text> */}
                  <Text>
                    {t(
                      "timetable.focustime.screen.button.select.screenshare.button.text"
                    )}
                  </Text>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedStudent(null);
                    setSelectedImage(null);
                    startSharing();
                    dispatch(
                      setSocketData({
                        method: "POST",
                        uri: "/classroom/sendImage",
                        groupId: activeClassroom?.groupId,
                        clientId: user?.clientId,
                        type: "CLASS_UPDATE",
                        data: "",
                      })
                    );
                  }}
                >
                  {/* <Text>나의 화면 공유</Text> */}
                  <Text>
                    {t(
                      "timetable.focustime.screen.button.teacher.screenshare.button.text"
                    )}
                  </Text>
                </MenuItem>
              </MenuList>
            </Menu>
          </VStack>
          <Tooltip
            hasArrow
            // label="수업 중에 학생들의 화면을 잠그어 학생들이 선생님에게 집중할 수 있도록 도움을 줍니다."
            label={t(
              "timetable.focustime.screen.button.screenlock.description"
            )}
          >
            <IconButton
              borderRadius="full"
              icon={<LockIcon color={isStdLocked ? "white" : "black"} />}
              backgroundColor={isStdLocked ? "#2E76EC" : ""}
              _hover={{ backgroundColor: "#4684e7" }}
              onClick={handleLockScreen}
            />
          </Tooltip>
          <Tooltip
            hasArrow
            // label="실시간 주의 집중 기능을 통해 학생 모니터링 화면에 주의 집중 상태를 표시할 수 있습니다."
            label={t(
              "timetable.focustime.screen.button.realtime.report.description"
            )}
          >
            <IconButton
              borderRadius="full"
              icon={
                <RiFocus2Fill
                  color={activeClassroomEyeTracking ? "white" : "black"}
                />
              }
              backgroundColor={activeClassroomEyeTracking ? "#2E76EC" : ""}
              _hover={{ backgroundColor: "#4684e7" }}
              onClick={() => {
                setIsFocus((prev) => !prev);
              }}
            />
          </Tooltip>
          <Tooltip
            hasArrow
            // label="선생님의 대화면을 전체화면으로 볼 수 있습니다."
            label={t(
              "timetable.focustime.screen.button.fullscreen.description"
            )}
          >
            <IconButton
              borderRadius="full"
              icon={<FullscreenIcon />}
              // color={isCurrentlySharing ? "primary" : "white"}
              onClick={onOpen}
            />
          </Tooltip>
          <Tooltip
            hasArrow
            // label="진행중인 포커스타임(수업)을 종료할 수 있습니다. 종료 하시게되면 학생들의 화면 모니터링이 중단됩니다."
            label={t(
              "timetable.focustime.screen.button.focustimeend.description"
            )}
          >
            <Button onClick={clickClassEnd}>
              {/* 수업 종료 */}
              {t("timetable.focustime.button.end")}
            </Button>
          </Tooltip>
        </HStack>
      </HStack>
    </Box>
  );
};

export default TeacherScreenToolbar;

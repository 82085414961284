import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { setSocketData, createManagingPromise } from "@store/actions";
import { useDispatch, useSelector } from "react-redux";
import { selectGroupProgramconfigs } from "@store/selectors";
import styles from "./ModalAddManageApp.module.scss";
import { Dropdown } from "../Modal/ModalEditClassTimetable";
import { timeList } from "@app/Constants/groupTimetable";
import { useParams } from "react-router-dom";
import moment from "moment";
import momentTimezone from "moment-timezone";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  deleteManagingPromise,
  getActiveManagingPromise,
  updateManagingPromise,
} from "@app/store/managing/actions";
import { selectActiveManaging, selectManagingById } from "@app/store/selectors";
import ModalAddManagingApp, {
  addSpaceBeforeCapitalLetters,
} from "./ModalAddManagingApp";
import CardManagingControlTest from "../Card/CardManagingControlTest";
import {
  createProgramconfigPromise,
  deleteProgramconfigPromise,
  updateProgramconfigPromise,
} from "@app/store/actions";
import samsungInternet from "../../assets/images/appIcons/samsungInternet.png";
import google from "../../assets/images/appIcons/google.png";
import { useTranslation } from "react-i18next";

const ModalAddManageApp = ({
  group,
  onHide,
  isUpdateOrDeleteModal,
  setIsUpdateOrDeleteModal,
  managingIdOfSchedule,
  setManagingIdOfSchedule,
  ...props
}) => {
  const { t, i18n } = useTranslation();

  const { groupId } = useParams();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const selectedManagingData = useSelector((state) =>
    selectManagingById(state, managingIdOfSchedule)
  );

  const [modalShow, setModalShow] = useState(false);

  const [title, setTitle] = useState();

  // const [repeatRule, setRepeatRule] = useState("rRule", "반복 안함");
  const [repeatRule, setRepeatRule] = useState(
    "rRule",
    t("webapplimit.group.modal.repeatoption.none")
  );

  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();

  const [endDate, setEndDate] = useState();
  const [endTime, setEndTime] = useState();

  const [formCheck, setFormCheck] = useState(false);
  const [filedCheck, setFiledCheck] = useState(false);

  const programconfigsManaging = useSelector((state) =>
    selectGroupProgramconfigs(state, managingIdOfSchedule)
  );

  const programIdsInWatchdogsManaging = programconfigsManaging.map(
    (x) => x.programId
  );

  const [
    newProgramIdsInWatchdogsManaging,
    setNewProgramIdsInWatchdogsManaging,
  ] = useState([...programIdsInWatchdogsManaging]);

  const [programConfigData, setProgramConfigData] = useState({});

  const [first, setFirst] = useState(true);

  const managingSession = useSelector((state) =>
    selectActiveManaging(state, groupId)
  );

  let aa = newProgramIdsInWatchdogsManaging.map((id) => {
    return { programId: id };
  });

  const foraxios = aa.map((a) => {
    return {
      ...a,
      dayMax: programConfigData[a.programId] * 3600 || 0,
      clientId: user.clientId,
      isAllowed: false,
    };
  });

  const createNewManage = () => {
    if (
      !startDate ||
      !startTime ||
      !endDate ||
      !endTime ||
      !title ||
      !repeatRule
    ) {
      alert("정보를 모두 입력해주세요");
      return;
    }

    dispatch(
      createManagingPromise({
        groupId: groupId,
        title: title,
        startDate: moment(`${startDate},${startTime}`).toISOString(),
        endDate: moment(`${endDate},${endTime}`).toISOString(),
        rRule: repeatRule,
        programconfigs: JSON.stringify(foraxios),
      })
    ).then((res) => {
      dispatch(
        setSocketData({
          method: "POST",
          uri: "/classroom/sendImage",
          groupId: group.groupId,
          clientId: user.clientId,
          type: "MANAGING_UPDATE",
          data: "",
        })
      );
      dispatch(getActiveManagingPromise(user?.clientId));
    });
  };

  const onDelete = () => {
    dispatch(deleteManagingPromise(managingIdOfSchedule))
      .then(() => {
        dispatch(
          setSocketData({
            method: "POST",
            uri: "/classroom/sendImage",
            groupId: group.groupId,
            clientId: user.clientId,
            type: "MANAGING_DELETE",
            data: group.groupId,
          })
        );
      })
      .then(() => dispatch(getActiveManagingPromise(user?.clientId)));
  };

  const onSave = () => {
    dispatch(
      setSocketData({
        method: "POST",
        uri: "/classroom/sendImage",
        groupId: group.groupId,
        clientId: user.clientId,
        type: "UPDATE_MANAGING_PROGRAMCONFIG",
        data: managingIdOfSchedule,
      })
    );
    dispatch(
      setSocketData({
        method: "POST",
        uri: "/classroom/sendImage",
        groupId: group.groupId,
        clientId: user.clientId,
        type: "MANAGING_START",
        data: group.groupId,
      })
    );
  };

  const updateManage = async () => {
    if (!startTime || !endTime) {
      alert("수정할 시간을 선택해주세요");
      return;
    }
    const isActiveManaging =
      managingSession?.filter(
        (activeManaging) => activeManaging.managingId === managingIdOfSchedule
      ).length > 0;
    if (isActiveManaging) {
      alert("현재 진행중인 관리입니다. 관리를 종료한 후 수정해주세요");
      return;
    }

    const updateData = {
      managingId: managingIdOfSchedule,
      title: title,
      rRule: repeatRule,
      startDate: moment(`${startDate},${startTime}`).toISOString(),
      endDate: moment(`${endDate},${endTime}`).toISOString(),
    };
    dispatch(updateManagingPromise(updateData))
      .then((res) => {
        dispatch(
          setSocketData({
            method: "POST",
            uri: "/classroom/sendImage",
            groupId: group.groupId,
            clientId: user.clientId,
            type: "MANAGING_UPDATE",
            data: managingIdOfSchedule,
          })
        );
        setManagingIdOfSchedule(res.managingId);
      })
      .then(() => dispatch(getActiveManagingPromise(user?.clientId)));

    const toBeRemoved = programIdsInWatchdogsManaging.filter(
      (id) => !newProgramIdsInWatchdogsManaging.includes(id)
    );

    const toBeCreated = newProgramIdsInWatchdogsManaging.filter(
      (id) => !programIdsInWatchdogsManaging.includes(id)
    );

    const requestRemovings = [...new Set(toBeRemoved)].map((id) =>
      dispatch(
        deleteProgramconfigPromise(
          programconfigsManaging.find((x) => x.programId === id).programconfigId
        )
      ).then(() => {
        dispatch(
          setSocketData({
            method: "POST",
            uri: "/classroom/sendImage",
            groupId: group.groupId,
            clientId: user.clientId,
            type: "DELETE_MANAGING_PROGRAMCONFIG",
            data: programconfigsManaging.find((x) => x.programId === id)
              .programconfigId,
            // programconfigs.find(
            //   (x) => x.programId === managingIdOfSchedule
            // ).programconfigId,
          })
        );
      })
    );
    const prevConfig = [...new Set(foraxios)]
      .filter((data) => programIdsInWatchdogsManaging.includes(data.programId))
      .map((data) => {
        dispatch(
          updateProgramconfigPromise({
            isAllowed: "false",
            programconfigId: programconfigsManaging.find(
              (aa) => aa.programId === data.programId
            ).programconfigId,
            dayMax: data.dayMax,
          })
        );
      });
    const requestCreatings = [...new Set(toBeCreated)].map((id) =>
      dispatch(
        createProgramconfigPromise({
          programId: id,
          groupId: managingIdOfSchedule,
          clientId: user.clientId,
          dayMax: foraxios.filter((aa) => aa.programId === id)[
            foraxios.filter((aa) => aa.programId === id).length - 1
          ].dayMax,
        })
      ).then((programconfig) => {
        dispatch(
          setSocketData({
            method: "POST",
            uri: "/classroom/sendImage",
            groupId: group.groupId,
            clientId: programconfig.clientId,
            type: "CREATE_MANAGING_PROGRAMCONFIG",
            data: managingIdOfSchedule,
          })
        );
      })
    );
    await Promise.all([
      ...requestRemovings,
      ...requestCreatings,
      ...prevConfig,
    ]).then(() => {
      window.location.reload();
    });
  };

  useEffect(() => {
    if (!selectedManagingData) {
      return;
    }
    const koreaTimeForStart = moment(selectedManagingData.startDate).format(
      "YYYY-MM-DD"
    );
    const koreaTimeForEnd = moment(selectedManagingData.endDate).format(
      "YYYY-MM-DD"
    );
    const koreaTimeForStartTime = momentTimezone(
      selectedManagingData.startDate
    ).format("HH:mm");
    const koreaTimeForEndTime = momentTimezone(
      selectedManagingData.endDate
    ).format("HH:mm");
    setTitle(selectedManagingData.title);
    setStartDate(koreaTimeForStart);
    setStartTime(koreaTimeForStartTime);
    setEndDate(koreaTimeForEnd);
    setEndTime(koreaTimeForEndTime);
    setRepeatRule(selectedManagingData.rRule);
  }, [selectedManagingData]);

  useEffect(() => {
    const temp = {};
    for (let i of programconfigsManaging) {
      temp[i.programId] = i.dayMax / 3600 || 0;
    }
    setNewProgramIdsInWatchdogsManaging([...programIdsInWatchdogsManaging]);
    setProgramConfigData(temp);
  }, [managingIdOfSchedule]);

  useEffect(() => {
    if (
      moment(`${startDate},${startTime}`).isAfter(
        moment(`${endDate},${endTime}`)
      )
    ) {
      setFormCheck(true);
      return;
    }
    setFormCheck(false);
  }, [startTime, endTime, startDate, endDate]);

  // title, startDate, endDate, startTime, endTime의 값들을 모두 creatingFields에 넣어준다.
  const creatingFields = useMemo(() => {
    return {
      title,
      startDate,
      endDate,
      startTime,
      endTime,
    };
  }, [title, startDate, endDate, startTime, endTime]);

  const creatingManagingDisabled = useMemo(() => {
    if (!title) {
      return true;
    }
    if (formCheck) {
      return true;
    }
    const values = Object.values(creatingFields);
    return values.some((value) => value === undefined || value === "");
  });

  // const editingTimetableDisabled = useMemo(() => {
  //   if (!subjectName) {
  //     return true;
  //   }
  //   if (timeCheck) {
  //     return true;
  //   }
  //   const values = Object.values(editingFields);
  //   return values.some((value) => value === undefined || value === '');
  // }, [editingFields, subjectName, timeCheck]);

  // const timeList = [
  //   ["rRule", "반복 안함"],
  //   ["FREQ=DAILY", "매일"],
  //   ["FREQ=WEEKLY", "매주"],
  // ];
  const timeList = [
    ["rRule", t("webapplimit.group.modal.repeatoption.none")],
    ["FREQ=DAILY", t("webapplimit.group.modal.repeatoption.everyday")],
    ["FREQ=WEEKLY", t("webapplimit.group.modal.repeatoption.everyweek")],
  ];
  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={styles["AddApp-container"]}
      >
        <Modal.Header className={styles["modal-bg-header"]}>
          {/* {isUpdateOrDeleteModal ? '자기관리 일정 수정' : '자기관리 일정 추가'} */}
          {isUpdateOrDeleteModal
            ? t("webapplimit.group.edit.title")
            : t("webapplimit.group.add.title")}
        </Modal.Header>
        <Modal.Body className={styles["modal-bg-body"]}>
          <div className={styles["body-title"]}>
            {/* <div className={styles["choice-title"]}>제목</div> */}
            <div className={styles["choice-title"]}>
              {t("webapplimit.group.modal.title")}
            </div>
            <input
              className={styles["group-input"]}
              name="subjectName"
              // placeholder={"평일 수업 시간 외 관리"}
              placeholder={t("webapplimit.group.modal.title.placeholder")}
              value={title || ""}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              maxLength={35}
            />
            <div className={styles["dateTitle"]}>
              {/* <div className={styles["choice-title"]}>기간</div> */}
              <div className={styles["choice-title"]}>
                {t("webapplimit.group.modal.time")}
              </div>
              <div className={styles["dateSection"]}>
                <div className={styles["startDate"]}>
                  <input
                    type="date"
                    value={startDate || ""}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                  />
                </div>
                <div className={styles["startTime"]}>
                  <input
                    type="time"
                    value={startTime || ""}
                    onChange={(e) => {
                      setStartTime(e.target.value);
                    }}
                  />
                </div>
                -
                <div className={styles["endDate"]}>
                  <input
                    type="date"
                    value={endDate || ""}
                    min={startDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                  />
                </div>
                <div className={styles["endTime"]}>
                  <input
                    type="time"
                    value={endTime || ""}
                    onChange={(e) => {
                      setEndTime(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={styles["formCheck"]}>
                {formCheck && (
                  // <div className={styles["formCheckText"]}>
                  //   시작시간과 종료시간 설정이 잘못되었습니다.
                  // </div>
                  <div className={styles["formCheckText"]}>
                    {t("webapplimit.group.modal.time.errormessage")}
                  </div>
                )}
              </div>
            </div>
            {/* <div className={styles["choice-title2"]}>반복 설정</div> */}
            <div className={styles["choice-title2"]}>
              {t("webapplimit.group.modal.repeatoption")}
            </div>
            <div className={styles["grade-drop"]}>
              <Dropdown
                className={styles["pattern-dropdown"]}
                // title='repeatRule'
                name="grade"
                value={repeatRule}
                onChange={(e) => {
                  console.log(e.target.value);
                  setRepeatRule(e.target.value);
                }}
                data={timeList}
              />
            </div>
            <div className={styles["managingApp-title"]}>
              <div className={styles["choice-title2"]}>
                {/* 자기관리용 앱/웹사이트 */}
                {t("webapplimit.group.modal.selfmanagement")}
              </div>
              <div
                className={styles["managingApp-buttonTitle"]}
                type="button"
                onClick={() => {
                  setModalShow(true);
                  // const temp = {};
                  // for (let i of programconfigsManaging) {
                  //   temp[i.programId] = i.dayMax / 3600 || 0;
                  // }
                  // setNewProgramIdsInWatchdogsManaging([
                  //   ...programIdsInWatchdogsManaging,
                  // ]);
                  // setProgramConfigData(temp);
                }}
              >
                <SettingsIcon className={styles["settingButton"]} />
                <button className={styles["managingApp-button"]}>
                  {/* 제어할 앱과 웹사이트 선택 */}
                  {t("webapplimit.group.modal.selfmanagement.select")}
                </button>
              </div>
            </div>
            <div className={styles["applist"]}>
              <div className={styles["list-box"]}>
                <div className={styles["icon-wrap"]}>
                  <img className={styles["icon"]} src={samsungInternet} />
                  <div
                    className={styles["app-title"]}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {/* 삼성인터넷브라우저 */}
                    {i18n.language === "ko"
                      ? "삼성인터넷브라우저"
                      : "Samsung Internet"}
                    <div style={{ fontSize: 10 }}>(Android)</div>
                  </div>
                  <div className={styles["dropdown-btn-wrap"]}>
                    {/* 사용못함 */}
                    {i18n.language === "ko" ? "사용못함" : "Not available"}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles["applist"]}>
              <div className={styles["list-box"]}>
                <div className={styles["icon-wrap"]}>
                  <img className={styles["icon"]} src={google} />
                  <div
                    className={styles["app-title"]}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {/* 구글브라우저 */}
                    {i18n.language === "ko" ? "구글브라우저" : "Google"}
                    <div style={{ fontSize: 10 }}>(Android)</div>
                  </div>
                  <div className={styles["dropdown-btn-wrap"]}>
                    {/* 사용못함 */}
                    {i18n.language === "ko" ? "사용못함" : "Not available"}
                  </div>
                </div>
              </div>
            </div>
            <ProgramManagementPanel
              key="applications"
              watchdogs={aa}
              classDuration={123123}
              clientId={user.clientId}
              groupId={groupId}
              newProgramIdsInWatchdogsManaging={
                newProgramIdsInWatchdogsManaging
              }
              setNewProgramIdsInWatchdogsManaging={
                setNewProgramIdsInWatchdogsManaging
              }
              setProgramConfigData={setProgramConfigData}
              programConfigData={programConfigData}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className={styles["modal-bg-footer"]}>
          <div className={styles["button-container"]}>
            <button
              className={styles["cancel-button"]}
              type="button"
              onClick={() => {
                onHide();
                setIsUpdateOrDeleteModal(false);
                setManagingIdOfSchedule();
                setTitle();
                setStartTime();
                setStartDate();
                setEndDate();
                setEndTime();
                setRepeatRule();
                // setProgramConfigData({});
                setNewProgramIdsInWatchdogsManaging([]);
                setFirst(true);
              }}
            >
              {/* 취소 */}
              {t("webapplimit.group.modal.cancel")}
            </button>
            <div className={styles["addDeleteButtonArea"]}>
              {isUpdateOrDeleteModal && (
                <span
                  className={styles["delete-button"]}
                  type="button"
                  onClick={() => {
                    // createNewManageGroup();
                    onDelete();
                    onHide();
                    setIsUpdateOrDeleteModal(false);
                    setManagingIdOfSchedule();
                    setTitle();
                    setStartTime();
                    setStartDate();
                    setEndDate();
                    setEndTime();
                    setRepeatRule();
                  }}
                >
                  {/* 삭제 */}
                  {t("webapplimit.group.modal.delete")}
                </span>
              )}
              {isUpdateOrDeleteModal ? null : (
                <button
                  className={
                    styles[
                      creatingManagingDisabled
                        ? "save-button-disabled"
                        : "save-button"
                    ]
                  }
                  disabled={creatingManagingDisabled}
                  type="button"
                  onClick={async () => {
                    if (isUpdateOrDeleteModal) {
                      updateManage();
                    } else {
                      createNewManage();
                    }
                    onHide();
                    setIsUpdateOrDeleteModal(false);
                    setManagingIdOfSchedule();
                    setTitle();
                    setStartTime();
                    setStartDate();
                    setEndDate();
                    setEndTime();
                    setRepeatRule("rRule", "반복 안함");
                    setFirst(true);
                    setProgramConfigData({});
                    setNewProgramIdsInWatchdogsManaging([]);
                  }}
                >
                  {/* 저장 */}
                  {t("webapplimit.group.modal.submit")}
                </button>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <ModalAddManagingApp
        show={modalShow}
        onHide={() => setModalShow(false)}
        group={group}
        managingIdOfSchedule={managingIdOfSchedule}
        newProgramIdsInWatchdogsManaging={newProgramIdsInWatchdogsManaging}
        setNewProgramIdsInWatchdogsManaging={
          setNewProgramIdsInWatchdogsManaging
        }
        programIdsInWatchdogsManaging={programIdsInWatchdogsManaging}
        setProgramConfigData={setProgramConfigData}
        programConfigData={programConfigData}
      />
    </>
  );
};

const ProgramManagementPanel = ({
  watchdogs = [],

  newProgramIdsInWatchdogsManaging,
  setNewProgramIdsInWatchdogsManaging,
  setProgramConfigData = { setProgramConfigData },
  programConfigData = { programConfigData },
}) => {
  const state = useSelector((state) => state);
  const { i18n } = useTranslation();
  return (
    <div name="program" className={styles["panel"]}>
      <div className={styles["app-panel"]}>
        {watchdogs.map((watchdog, index) => {
          const program = state.programs.byId[watchdog.programId];

          return (
            <CardManagingControlTest
              key={index}
              watchdog={watchdog}
              name={
                i18n.language === "ko"
                  ? program.korName
                  : addSpaceBeforeCapitalLetters(program.engName)
              }
              alias={program?.alias}
              newProgramIdsInWatchdogsManaging={
                newProgramIdsInWatchdogsManaging
              }
              setNewProgramIdsInWatchdogsManaging={
                setNewProgramIdsInWatchdogsManaging
              }
              setProgramConfigData={setProgramConfigData}
              programConfigData={programConfigData}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ModalAddManageApp;

import {
  useProgramconfigs,
  useUpdateProgramconfig,
} from "@app/hooks/queries/programconfigs/useProgramConfigs";
import { SimpleGrid, Spinner, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CardClassManagementsSmall from "../Card/CardClassManagementsSmall";
import { useDispatch, useSelector } from "react-redux";
import { setSocketData } from "@app/store/actions";
import SendBlockedWebpageListModule from "@app/utils/classroom/sendMessageModule";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const ClassManagementWrapper = () => {
  const { groupId } = useParams();
  const cacheData = useQueryClient();

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [programconfigs, setProgramconfigs] = useState(null);

  const programconfigData = cacheData.getQueryData(["programconfig", groupId]);

  const [blockedWebPageListString, setBlockedWebPageListString] = useState(
    null
  );

  const [blockedProgramconfigs, setBlockedProgramconfigs] = useState(null);

  // // const programconfigData = useProgramconfigs(groupId);
  const program = useSelector((state) => state.programs.byId);

  const { updateProgramconfigAsync } = useUpdateProgramconfig(groupId);

  // const programconfigs = programconfigData?.data.programconfigs;
  useEffect(() => {
    if (!programconfigData) {
      return;
    }
    const programconfigs = programconfigData?.data.programconfigs;
    setProgramconfigs(programconfigs);
  }, [programconfigData]);

  const programconfigsWithProgram = programconfigs?.map((programconfig) => {
    return {
      ...programconfig,
      ...program[programconfig.programId],
    };
  });
  // const blockedProgramconfigs = programconfigsWithProgram?.filter(
  //   (programconfig) => !programconfig.isAllowed
  // );

  useEffect(() => {
    if (!programconfigsWithProgram) {
      return;
    }

    const blockedProgramconfigs = programconfigsWithProgram?.filter(
      (programconfig) => !programconfig.isAllowed
    );

    setBlockedProgramconfigs(blockedProgramconfigs);
  }, [programconfigs]);

  useEffect(() => {
    const buildProgramconfigsWebPageList = async (blockedProgramconfigs) => {
      if (!blockedProgramconfigs) {
        return;
      }

      const blockedWebPageList = await Promise.all(
        blockedProgramconfigs.map((blockedProgramconfig) => {
          if (blockedProgramconfig?.web === undefined) {
            return "_____";
          }
          return blockedProgramconfig?.web;
        })
      );

      const blockedWebPageListString = blockedWebPageList.join(",");

      return blockedWebPageListString;
    };

    buildProgramconfigsWebPageList(blockedProgramconfigs).then((res) => {
      setBlockedWebPageListString(res);
    });
  }, [blockedProgramconfigs]);

  const sendUpdateProgramconfigList = (blockedWebPageListString) => {
    dispatch(
      setSocketData({
        method: "POST",
        uri: "/classroom/sendImage",
        groupId: groupId,
        clientId: user?.clientId,
        type: "UPDATE_FORBID_LIST",
        data: blockedWebPageListString,
      })
    );
  };

  useEffect(() => {
    sendUpdateProgramconfigList(blockedWebPageListString);
  }, [blockedWebPageListString]);
  const { t, i18n } = useTranslation();
  return (
    <>
      <SimpleGrid
        columns={{
          sm: 1,
          md: 1,
          lg: 2,
          xl: 3,
        }}
        spacing={2}
        h="200"
        overflowY="scroll"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#ccc",
            borderRadius: "24px",
          },
        }}
      >
        {programconfigsWithProgram ? (
          programconfigsWithProgram.map((programconfig) => {
            return (
              <CardClassManagementsSmall
                key={programconfig.programconfigId}
                id={programconfig.programconfigId}
                enabled={programconfig.isAllowed}
                name={
                  i18n.language === "ko"
                    ? programconfig?.korName
                    : programconfig?.engName
                }
                alias={programconfig?.alias}
                groupId={programconfig.groupId}
                updateProgramconfigAsync={updateProgramconfigAsync}
              />
            );
          })
        ) : (
          <VStack>
            <Text
              fontSize="lg"
              fontWeight="bold"
              color="gray.400"
              textAlign="center"
            >
              추가하신 앱/웹 제어 리스트가 존재하지 않습니다.
            </Text>
          </VStack>
        )}
      </SimpleGrid>
    </>
  );
};

export default ClassManagementWrapper;

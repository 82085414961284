import QuizTypeMultiplePreview from "@app/components/Quiz/Common/QuizTypeMultiplePreview";
import QuizTypeLongPreview from "@app/components/Quiz/Common/QuizTypeLongPreview";
import QuizTypeShortPreview from "@app/components/Quiz/Common/QuizTypeShortPreview";
import QuizTypeStylusPreview from "@app/components/Quiz/Common/QuizTypeStylusPreview";
import QuizTypeOXPreview from "@app/components/Quiz/Common/QuizTypeOXPreview";
import { Button, Card, Flex } from "@chakra-ui/react";
import {
  sortableKeyboardCoordinates,
  arrayMove,
  useSortable,
  defaultAnimateLayoutChanges,
} from "@dnd-kit/sortable";

import { CSS } from "@dnd-kit/utilities";
import React from "react";
import { useTranslation } from "react-i18next";

function SortableQuizPreviewCard({ quiz, setQuizId, setEditMode }) {
  const animateLayoutChanges = (args) =>
    args.isSorting || args.wasDragging
      ? defaultAnimateLayoutChanges(args)
      : true;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ animateLayoutChanges, id: String(quiz.quizId) });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const renderQuizContent = (quiz) => {
    switch (quiz.quizType) {
      case "LONG":
        return <QuizTypeLongPreview quizId={quiz.quizId} />;
      case "OX":
        return <QuizTypeOXPreview quizId={quiz.quizId} />;
      case "STYLUS":
        return <QuizTypeStylusPreview quizId={quiz.quizId} />;
      case "SHORT":
        return <QuizTypeShortPreview quizId={quiz.quizId} />;
      default:
        return <QuizTypeMultiplePreview quizId={quiz.quizId} />;
    }
  };
  const { t } = useTranslation();
  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{ ...style, opacity: isDragging ? 0.5 : 1 }}
    >
      <Card
        border="1px"
        borderColor="gray.200"
        boxShadow="sm"
        p={4}
        w="80%"
        m="auto"
      >
        {renderQuizContent(quiz)}
        <Flex justifyContent="flex-end" mt={4}>
          <Button
            w={40}
            onClick={() => {
              setQuizId(quiz.quizId);
              setEditMode(true);
            }}
            textTransform={"capitalize"}
          >
            {/* 편집하기 */}
            {t("edit")}
          </Button>
        </Flex>
      </Card>
    </div>
  );
}

export default SortableQuizPreviewCard;
